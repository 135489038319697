.ReactModal__Body--open {
  overflow: hidden;
}
.modal {
  margin: 0 auto;
  max-width: 600px;
  max-height: calc(100vh - 6rem);
  background: white;
  overflow: auto;
  border-radius: 0;
  -webkit-overflow-scrolling: touch;
  outline: none;
  position: relative;
  margin-top: 3rem;
  padding: 2rem;
  position: relative;
}

.modal.large {
  max-width: 800px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.666);
  z-index: 10;
}

.modal.modal-confirmation-delete.modal-confirmation-delete-task {
  max-height: 100%;
}

.modal .close-icon {
  text-indent: -10000px;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
