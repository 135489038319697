.product-stock-action .product-stock-action-input {
  align-items: center;
}
.product-stock-action .product-minimum-quantity {
  text-align: center;
  flex-grow: 1;
}
.product-stock-action .ui.segment.favorites-form {
  background: white;
  min-height: 15rem;
}
.product-stock-action .ui.segment.favorites-form.multiple-lists {
  margin-top: 0;
  min-height: 12rem;
  max-width: 16rem;
  border: none;
  box-shadow: none;
}
.product-stock-action .favorites-form .ui.header.icon {
  font-weight: normal;
  font-size: 1.1rem;
}
.product-stock-action i.green.icon {
  color: #00a98c !important;
}
.product-stock-action .delete-location-list-product {
  text-align: center;
}
.product-stock-action .ui.menu.secondary.vertical .item {
  color: grey;
}
.product-stock-action .ui.menu.secondary.vertical .item.active {
  border-color: #00a98c;
  color: #00a98c;
}

.product-stock-action
  .product-minimum-quantity
  input[type='number'].input-quantity {
  margin-bottom: 0;
}

.product-stock-action .product-stock-action-controls {
  margin: 2rem 0 1rem;
}
.product-stock-action .product-stock-action-controls button {
  min-width: 50%;
  margin: 0 auto;
  display: block;
}
