/*
 * OVERRIDES
 */
.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.3);
}
.introjs-button {
  font-family: inherit;
  border-radius: 10px;
  font-weight: bold;
  letter-spacing: 0.03rem;
}
.introjs-tooltip {
  border-radius: 0;
  box-shadow: none;
}
.introjs-tooltipbuttons a {
  border: none;
  padding: 0.5rem 1rem;
  background-image: none;
  background-color: #ececec;
  color: black;
  text-shadow: none;
}
.introjs-tooltipbuttons a.introjs-prevbutton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.introjs-tooltipbuttons a.introjs-nextbutton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #00a98c;
  color: white;
}
.introjs-tooltipbuttons a:active,
.introjs-tooltipbuttons a:focus {
  background-image: none;
}
.introjs-tooltipbuttons a.introjs-disabled {
  color: white;
  background-color: #ececec;
}

.introjs-tooltiptext h2 {
  margin: 1rem auto;
}
.introjs-tooltiptext p {
  margin: 1rem auto;
}

.introjs-tooltip.tooltip-big {
  margin: 1rem auto 1rem -230px;
  min-width: 350px;
  max-width: 100%;
  padding: 2rem;
}
