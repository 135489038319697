.product-cart-action .product-cart-action-inputs {
  text-align: center;
}
.product-cart-action .product-cart-action-controls {
  margin: 2rem 0 1rem;
}
.product-cart-action .product-cart-action-legend {
  text-align: center;
}
.product-cart-action-controls button.primary {
  min-width: 50%;
  display: block;
  margin: 0 auto;
}
