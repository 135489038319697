.settings-panel {
  margin: 5rem 0;
  padding-left: 5rem;
  max-width: 50rem;

  @media (max-width: 450px) {
    margin: 1rem 0;
    padding-left: 0rem;
  }
}
.settings-panel .ui.header,
.settings-panel .ui.header.attached.top,
.settings-panel .ui.segment {
  border-top: none;
  background: none;
}
@media (max-width: 767px) {
  .settings-panel {
    margin-left: 0;
  }
}
