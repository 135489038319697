.search-item-product {
}
.search-item-product .search-item-product-image {
  display: inline-block;
}
.search-item-product .search-item-product-image img {
  width: 3rem;
  height: 3rem;
}
.search-item-product .search-item-product-brand-and-name {
  vertical-align: top;
  margin-top: 0.25rem;
  display: inline-block;
  margin-left: 0.5rem;
  width: calc(100% - 4rem);
}
