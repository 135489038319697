.role-summary-wrap {
  margin-top: 0.5rem;
  margin-left: 1.9rem;
}

.role-summary-wrap.compact {
  margin-left: 1.5rem;
}

span.role-summary {
  display: block;
}
