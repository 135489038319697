.onboarding-step {
  min-height: 100vh;
  display: flex;
  position: relative;
}
.onboarding-step .content-panel {
  min-height: 100vh;
  flex: 1;
  background: white;
}
.onboarding-step .content-panel .logo {
  display: block;
  margin: 3rem 0 0 3rem;
}
.onboarding-step .content-panel .control-panel-inner {
  padding: 1rem;
  margin: 4rem auto;
  max-width: 27rem;
}
.onboarding-step .image-panel {
  flex: 1;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../imgs/onboarding/desk.jpeg);
}

.ui.form .form--no-padding .field {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .onboarding-step {
    flex-direction: column-reverse;
  }
  .onboarding-step .image-panel {
    flex: 0.25;
    min-height: 7rem;
  }
  .onboarding-step .content-panel .control-panel-inner {
    margin: 2rem;
  }
}
