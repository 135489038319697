button.catalog-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 40px;
}

button.catalog-toggle:hover {
  background-color: transparent;
}

.catalog-toggle__content {
  cursor: pointer;
  color: #999da9;
}

.catalog-toggle__content.activated i {
  color: #00a98c;
}

.catalog-toggle__content i {
  margin: 0;
  font-size: 1rem;
}
