.product-budget-code-link {
  width: 100%;
  height: 7rem;
}
.product-budget-code-link .budget-name,
.product-budget-code-link .budget-code,
.product-budget-code-link .tertiary-link {
  display: block;
}
.product-budget-code-link .tertiary-link {
  margin-left: -0.5rem;
}
