.confirmations {
  position: fixed;
  top: 1rem;
  right: 0.75rem;
  z-index: 1000;
}
.ui.message.confirmation {
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset,
    0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  opacity: 0;
  transition: all 0.1s ease-in;
  transform: translate3d(0, 0, 0);
  width: 300px;
  max-height: 0;
  margin-bottom: 0;
  padding: 0 1.5em;
}
.ui.message.confirmation .content {
  transition: all 0.1s ease-in;
  max-height: 0;
}
.ui.message.confirmation.success {
  box-shadow: 0 0 0 1px #a3c293 inset, 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
}
.ui.message.confirmation.error {
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
}
.ui.message.confirmation.info {
  box-shadow: 0 0 0 1px #a9d5de inset, 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
}
.ui.message.confirmation-fadein {
  opacity: 1;
  max-height: 5rem;
  margin-bottom: 0.5rem;
  padding: 1.3em 1.5em;
}
.ui.message.confirmation-fadein .content {
  max-height: 5rem;
}
.confirmation:hover {
  cursor: pointer;
}
.confirmation-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 425px) {
  .confirmation {
    max-width: 100%;
    width: auto;
  }
}
