@media print {
  .delivery-info-pdf .location-name {
    margin-bottom: 1rem;
  }

  .delivery-info-pdf div.order-data-row {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 0.5rem 0;
  }

  .delivery-info-pdf div.order-data-row p.header.bold-text {
    width: 14rem;
    display: inline-block;
  }

  .delivery-info-pdf div.order-data-row p.data,
  .delivery-info-pdf div.order-data-row div.data {
    display: inline-block;
    vertical-align: top;
  }
}
