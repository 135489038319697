.spend-by-vendor-pie-table .pie-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 35%;
}
.spend-by-vendor-pie-table .table-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1rem;
  width: 60%;
  width: calc(65% - 1rem);
}
.spend-by-vendor-pie-table .external-vendor-invoice-upload {
  max-width: 20rem;
}
@media (max-width: 768px) {
  .spend-by-vendor-pie-table .pie-wrapper,
  .spend-by-vendor-pie-table .table-wrapper {
    width: 100%;
    display: block;
    margin: 0;
  }
}
