.supply-item-in-cart.flex-wrapper {
  color: #999daa;
  justify-content: space-between;
  align-items: center;
  display: block;
  text-align: center;
}

.supply-item-in-cart .item-cart-subheader {
  width: 100%;
  margin-bottom: 0.25rem;
  display: none;
}

.supply-item-in-cart .cart-icon {
  padding-top: 0.25rem;
  display: none;
}

.supply-item-in-cart .item-last-ordered {
  padding-top: 0.5rem;
}

.supply-item-in-cart .in-cart-quantity-container {
  width: 100%;
  padding-top: 0.75rem;
  text-align: center;
}

.supply-item-in-cart .item-add-to-cart-button {
  width: auto;
  max-width: 11rem;
  margin: auto;
  margin-top: 0.5rem;
}
.supply-item-in-cart button.add-to-cart {
  width: auto;
}
.supply-item-in-cart button.add-to-cart.tertiary {
  padding-top: 0.7rem;
  color: inherit;
}

.supply-item-in-cart .add-to-cart-button-label {
  font-size: 1rem;
}

.item-add-to-cart-form.flex-wrapper {
  display: block;
}

.supply-item
  .item-product-in-cart-status
  .input-quantity-controls
  .input-quantity {
  width: 5rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
  color: darkslategray;
  border-radius: 0;
  border: solid 1px #aaa;
}

.supply-item
  .item-product-in-cart-status
  .input-quantity-controls
  .input-quantity.disabled {
  border-color: #e3e3e3;
  color: #e3e3e3;
}

.supply-item .item-product-in-cart-status .input-quantity-increment,
.supply-item .item-product-in-cart-status .input-quantity-decrement {
  margin-top: 0.7rem;
  border: 1px solid;
  border-radius: 50%;
  font-weight: 300;
  height: 1.5rem;
  width: 1.5rem;
}
.supply-item .item-product-in-cart-status .input-quantity-increment {
  padding: 0 0 0 0.1rem;
}
.supply-item .item-product-in-cart-status .input-quantity-decrement {
  padding: 0 0.1rem;
}

.supply-item .item-product-in-cart-status .input-quantity-increment:hover,
.supply-item .item-product-in-cart-status .input-quantity-decrement:hover {
  background-color: transparent;
}

@media (min-width: 1221px) and (max-width: 1370px) {
  .supply-item .item-product-in-cart-status .input-quantity-increment,
  .supply-item .item-product-in-cart-status .input-quantity-decrement {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .supply-item
    .item-product-in-cart-status
    .input-quantity-controls
    .input-quantity {
    width: 4rem;
  }

  .supply-item .item-product-in-cart-status .input-quantity-increment,
  .supply-item .item-product-in-cart-status .input-quantity-decrement {
    padding: 0;
  }

  button.add-to-cart {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (max-width: 450px) {
  .supply-item-in-cart .column-one,
  .supply-item-in-cart .column-two {
    width: 100%;
  }

  .supply-item-in-cart .item-add-to-cart-form,
  .supply-item-in-cart .in-cart-quantity-container,
  .supply-item-in-cart .item-last-ordered {
    width: 100%;
    margin-top: 0.25rem;
  }
  .supply-item-in-cart .item-last-ordered {
    padding-top: 0;
    margin-top: -0.25rem;
  }
  .supply-item
    .item-product-in-cart-status
    .input-quantity-controls
    .input-quantity {
    width: 4rem;
  }

  .supply-item-in-cart.flex-wrapper {
    padding: 0.5rem 0 0;
  }

  .add-to-cart-container .supply-item-in-cart.flex-wrapper {
    padding: 0;
  }

  .supply-item-in-cart p.item-cart-subheader {
    padding-bottom: 0.25rem;
  }
}

@media (max-width: 400px) {
  .supply-item .item-product-in-cart-status .input-quantity-increment,
  .supply-item .item-product-in-cart-status .input-quantity-decrement {
    padding: 0;
  }
}
