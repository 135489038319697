.requisition-cart-flex.flex-item {
  width: 19rem;
}
.officeluv-category-container .requisition-cart-flex.flex-item {
  margin-top: 1rem;
}

@media (max-width: 1200px) {
  .requisition-cart-fixed,
  .requisition-cart-flex {
    display: none;
  }
}
