.quickbooks-billing-info-form .inline-inputs label {
  display: inline-block;
}
.quickbooks-billing-info-form .inline-inputs label input {
  margin-left: -0.75rem;
}
.quickbooks-billing-info-form .inline-inputs label:last-child input {
  width: calc(100% + 0.75rem);
}
.quickbooks-billing-info-form .update-bill-info button {
  margin: 0 0 0 0.5rem;
}
