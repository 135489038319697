.cleaning-details-empty-state .empty-shift-illustration {
  width: 11.089rem;
  height: 11.089rem;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  background-image: url(../../imgs/empty-attendant--green.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 6.854rem;
}

.cleaning-details-empty-state .empty-state-container {
  text-align: center;
  padding-bottom: 2rem;
}

.cleaning-details-empty-state .empty-state-container h3.empty-state-header {
  max-width: 25rem;
  margin: 0 auto 2rem;
}
.cleaning-details-empty-state .empty-state-container .empty-state-subheader {
  max-width: 25rem;
  margin: 0 auto 2rem;
}

.cleaning-details-empty-state
  .empty-state-container
  .empty-state-subheader.microcopy {
  margin: 0 auto 2rem;
}

.cleaning-details-empty-state .schedule-attendant-form-container {
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
}

.cleaning-details-empty-state .schedule-attendant-form-container form {
  max-width: 20rem;
  margin: 0 auto;
  text-align: left;
}

@media (max-width: 450px) {
  .cleaning-details-empty-state .empty-shift-illustration {
    width: 9.01rem;
    height: 9.01rem;
    margin: 5rem auto 2rem;
  }

  .cleaning-details-empty-state .empty-state-container h3,
  .cleaning-details-empty-state .empty-state-container .microcopy {
    width: 100%;
  }
}
