.product-similars-action table td h4.ui.image {
  display: block;
}
.product-similars-action table td h4 .content {
  width: calc(100% - 2rem);
}
.product-similars-action table td a h4 .content {
  color: #00a98c;
}
.ui.light.message {
  color: #555c6e;
  background: white;
  box-shadow: none;
  border: 2px solid #f3f4f5;
}
.product-similars-action .ui.light.message {
  border: none;
  padding-left: 0;
}
.product-similars-action .ui.list > .item .header {
  max-width: 7rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.product-similars-action .ui.list > .item {
  margin-left: 0;
  margin-right: 1rem;
}
.product-similars-action .ui.horizontal.list:not(.celled) > .item:last-child {
  margin-right: 0;
}
.product-similars-action .ui.list > .item .content a {
  text-decoration: none;
  color: inherit;
}
.product-similars-action .ui.list > .item .content a .header {
  color: #00a98c;
}

.product-similars-empty {
  text-align: center;
  width: 80%;
  max-width: 20rem;
  margin: auto;
}

.product-similars-empty p.message {
  color: darkgrey;
}

.product-similars-empty p {
  margin-bottom: 1rem;
}
