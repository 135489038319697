.input-quantity {
  -moz-appearance: textfield;
}
.input-quantity::-webkit-outer-spin-button,
.input-quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-quantity-wrap {
  display: block;
  padding: 0;
}
.input-quantity-label {
  display: block;
  padding: 0;
}
.input-quantity-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-quantity,
.input-quantity-increment,
.input-quantity-decrement {
  display: inline-block;
}
input[type='number'].input-quantity {
  width: 2.5rem;
  padding: 0.5rem 0.25rem;
  display: inline-block;
  text-align: center;
  margin-bottom: 0.25rem;
}

.input-quantity-increment,
.input-quantity-decrement {
  text-align: center;
  background: none;
  color: #00a98c;
  font-weight: 700;
  font-size: 1rem;
  box-shadow: none;
  height: 2.5rem;
  padding: 0 0.25rem;
  vertical-align: top;
}
.input-quantity-increment:hover,
.input-quantity-decrement:hover {
  background: #ebecee;
  color: #00a98c;
}

.product-actions .input-quantity-increment,
.product-actions .input-quantity-decrement {
  font-size: 2rem;
  padding: 0 0.4rem;
}

.ui.button.input-quantity-remove.tertiary {
  margin: 0 auto;
  font-weight: normal;
  display: block;
}

.input-quantity-readonly-wrap {
  font-size: larger;
  text-align: center;
}

.input-quantity-readonly-header {
  margin-bottom: 0.2rem;
}
