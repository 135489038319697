.onboarding-team-form form .fields:first-child .field .ui.checkbox {
  margin-top: 1.6rem;
}
.onboarding-team-form form .fields .field .ui.checkbox {
  margin-top: 0.5rem;
  font-size: 0.83rem;
}

/* semantic ui overrides*/
.ui.form .onboarding-team-form-dropdown.disabled,
.onboarding-team-form-dropdown .ui.dropdown.disabled {
  opacity: 1;
}
.onboarding-team-form-dropdown .ui.selection.dropdown {
  padding: 0.78571429em 1em 0.78571429em 1em;
}
.onboarding-team-form-dropdown .ui.selection.dropdown > .dropdown.icon {
  right: 0.75em;
}

.onboarding-team-form-dropdown .ui.dropdown.disabled .icon {
  display: none;
}

@media only screen and (max-width: 767px) {
  .onboarding-team-form .ui.form .fields.unstackable {
    margin-bottom: 1em;
  }
}
