.modal-brand {
}

.modal-brand h2 {
  margin-bottom: 1rem;
  color: #00a98c;
}

.modal-brand__image {
  width: 100%;
  max-width: 250px;
  float: left;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.modal-product__description {
  white-space: pre-wrap;
}
