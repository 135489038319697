.vendor-group {
  margin-top: 15px;
  padding: 0 8px;
  position: relative;
}

.vendor-group::before {
  content: '';
  position: absolute;
  bottom: 0;
  top: 3.8rem;
  width: 6px;
  left: 0;
  border: 3px solid lightgrey;
  border-right: none;
}

.vendor-group__min-value {
  color: darkslategrey;
}

.vendor-group .progress-wrap {
  margin-bottom: 22px;
}

.vendor-group .progress-wrap .label {
  font-size: 0.8rem;
  font-weight: normal;
  color: grey;
}

.vendor-group.icon {
  display: inline-block;
  background-size: 1.2rem;
  height: 1.2rem;
  width: 1.2rem;
  background-image: url('./../../imgs/icon-shipment--teal.svg');
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 0.4rem;
}

.vendor-group .big-box-wrap {
  margin-left: 0;
  justify-content: flex-end;
}

.vendor-group__min-text {
  color: #8e8e8e;
}
