.fixed-width-column.external-vendor-invoice-new {
  margin: 2rem auto 20vh;
}
.external-vendor-invoice-new .ui.segment.external-vendor-invoice-upload {
  margin: 0 auto 2rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.external-vendor-invoice-new
  .external-vendor-invoice-upload
  .ui.primary.button {
  display: none;
}
