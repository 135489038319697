.navbar-side {
  position: fixed;
  top: 0;
  width: 60px;
  height: 100vh;
  z-index: 5;
  transition: width 0.2s;
  background: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.5);
}

.navbar-side button.mobile {
  display: none;
}

.navbar-side.expanded {
  -webkit-box-shadow: 3px 2px 28px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 2px 28px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 2px 28px -4px rgba(0, 0, 0, 0.75);
}

.navbar-side.expanded.expandedPushContent {
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.5);
}

.navbar-side button,
.navbar-side button:hover,
.navbar-side button:active,
.navbar-mobile button,
.navbar-mobile button:hover,
.navbar-mobile button:active {
  background-color: transparent;
  outline: none;
  margin: 0;
  padding: 0;
}

.navbar-side i {
  color: #999da9;
}

#page-wrap {
  margin-left: 60px;
  transition: margin-left 0.2s;
}

@media print {
  #page-wrap {
    margin-left: 0 !important;
  }
}

#page-wrap.no-nav {
  margin-left: 0;
}

.navbar-side__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-side__bottom button {
  width: 60px;
  height: 40px;
  display: inline-block;
}

.navbar-side__header {
  height: 80px;
  border-bottom: 0.5rem solid rgb(245, 245, 245);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-side__header a {
  line-height: 1;
}

.navbar-side__greeting {
  display: flex;
  flex-direction: column;
  color: #888;
  text-align: right;
  margin-right: 1rem;
}

.navbar-side__greeting > div {
  font-size: 1rem;
}

.navbar-side__greeting i.icon {
  color: #999da9;
  font-size: 1.5rem;
  margin: 0;
  margin-right: -8px;
  cursor: pointer;
}

.navbar-side__expandable {
  display: block;
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition: 0.2s;
}

.navbar-side__expandable.expanded {
  visibility: visible;
  opacity: 1;
  width: calc(250px - 60px);
}

i.icon.navbar-side__toggle {
  font-size: 1.3rem;
  color: #999da9;
  transition: 0.2s;
}

i.icon.navbar-side__toggle:hover {
  color: #333;
}

.ui.inverted.menu.navbar-side__menu {
  flex-direction: column;
}

.ui.inverted.menu.navbar-side__menu,
.ui.inverted.accordion.navbar-side__accordion {
  background-color: rgb(245, 245, 245);
  margin-top: 0;
}

.ui.menu .navbar-side__menu-item {
  padding: 0;
  transition: 0.2s;
}

.ui.accordion .navbar-side__menu-item .title {
  display: flex;
  align-items: center;
  padding: 0;
  width: 60px;
  font-weight: bold;
}

.ui.accordion .navbar-side__menu-item.navbar-side__menu-item--expanded .title {
  width: 250px;
}

.navbar-side__menu-item.navbar-side__menu-item--collapsed
  .navbar-side__menu-item-title {
  display: none;
}

.navbar-side__menu-item--collapsed {
  width: 60px;
}
.navbar-side__menu-item--expanded {
  width: 250px;
}
.navbar-side__menu-item--current-page {
  background-color: rgb(245, 245, 245);
}
.navbar-side__menu-item--current-section {
  position: relative;
}
.navbar-side__menu-item--current-section:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 100%;
  content: '';
  background: #00a98c;
}

.navbar-side__menu-item-title {
  width: 100%;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
}

.navbar-side__menu-item-title,
.navbar-side__menu-item-title a {
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
  color: #535662;
  letter-spacing: 0.05rem;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
}

.navbar-side__menu-item--expanded .navbar-side__menu-item-title {
  visibility: visible;
  opacity: 1;
}

.navbar-side__submenu {
  display: none;
}

.ui.inverted.menu .navbar-side__submenu {
  background-color: rgb(245, 245, 245);
}
.ui.inverted.menu .navbar-side__submenu .item {
  padding-left: 60px;
  font-size: 1rem;
  color: #535662;
  padding: 0.8rem 3.7em;
  padding-right: 0.5rem;
  text-align: left;
  width: 100%;
  height: 40px;

  animation: fadein 0.8s;
  -moz-animation: fadein 0.8s; /* Firefox */
  -webkit-animation: fadein 0.8s; /* Safari and Chrome */
  -o-animation: fadein 0.8s; /* Opera */
}

.ui.inverted.menu .navbar-side__submenu .item:hover,
.ui.inverted.menu .navbar-side__submenu .item.navbar-side__current-page {
  color: #535662;
  background-color: #eee
}

.navbar-side__menu-item--expanded .navbar-side__submenu {
  display: block;
}

.navbar-side__menu-icon {
  font-size: 1.5rem;
  width: 60px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  line-height: 1;
  padding: 0 1rem;
}

.navbar-side__menu-icon i.icon {
  margin: 0;
  font-size: 1.3rem;
  color: #999da9;
  transition: color 0.2s;
}

.navbar-side__menu-icon:hover i.icon {
  color: #999da9;
}

i.icon.navbar-side__caret {
  height: auto;
  font-size: 1rem;
  float: right;
  color: #535662;
}

span.navbar-side__initials-wrap {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 1px solid #999da9;
  display: flex;
  align-items: center;
  justify-content: center;
}
span.navbar-side__initials {
  font-size: 0.8rem;
  color: #999da9;
}

.navbar-side__bottom button.navbar-side__lock {
  width: auto;
  height: auto;
  display: inline-block;
  color: #999da9;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 1rem;
  box-sizing: border-box;
}

.navbar-mobile {
  z-index: 2;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  display: none;
}

.navbar-mobile i.icon {
  font-size: 25px;
  line-height: 1;
  margin: 0;
}

.navbar-mobile__background {
  display: none;
}

@media screen and (max-width: 450px) {
  .navbar-side.desktop {
    display: none;
  }

  .navbar-side.desktop.expanded {
    display: flex;
  }

  .navbar-mobile {
    display: flex;
  }

  .navbar-mobile__background.expanded {
    display: block;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #page-wrap,
  #page-wrap.no-nav {
    margin-left: 0;
  }

  .navbar-side__bottom .desktop,
  .navbar-side__bottom button.desktop,
  .navbar-side__greeting .desktop,
  .navbar-side__greeting button.desktop {
    display: none;
  }

  .navbar-side__bottom .mobile,
  .navbar-side__bottom button.mobile,
  .navbar-side__greeting .mobile,
  .navbar-side__greeting button.mobile {
    display: inline-block;
  }
}

/** Animations for expanded text **/

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
