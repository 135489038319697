.cart-sub-pref .ui.dropdown .text {
  text-transform: capitalize;
}

.cart-sub-pref .ui.dropdown > .text {
  color: #00a98c;
}

.cart-sub-pref .ui.dropdown .item .text {
  font-size: 0.9rem;
}

.cart-sub-pref__custom {
  width: 100%;
  resize: vertical;
}

.cart-sub-pref__custom-wrap {
  position: relative;
  margin-top: 1rem;
}

button.cart-sub-pref__close {
  display: block;
  position: absolute;
  right: -21px;
  top: 0;
  cursor: pointer;
  line-height: 1;
  outline: none;
  border: none;
  background-color: white;
  padding: 0;
  margin: 0;
  border-radius: 3px;
  cursor: pointer;
  z-index: 1;
}

button.cart-sub-pref__close i.icon {
  color: #535662;
  margin: 0;
  line-height: 1;
  cursor: pointer;
}

.custom-preference-saved {
  width: 100%;
  display: block;
  text-align: right;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 0.7rem;
}
