.schedule-form {
  background: white;
  padding: 0.1rem 1rem;
  border: 1px solid #c1c1c1;
}

.schedule-form label {
  padding-left: 0;
}

.schedule-form__text {
}

.schedule-form .form-radio-row {
  margin: 1rem 0;
}

.schedule-form .form-radio-row input {
  float: left;
  margin-right: 0;
}

.schedule-form .form-radio-row p {
  margin-bottom: 10px;
}

.schedule-form .form-radio-row label span {
  margin-left: 0.6rem;
}

/* Semantic UI overrides */
.schedule-form .accordion-content {
}

.schedule-form.bottom-border .accordion-content {
  border-bottom: 1px solid #ddd;
}

.schedule-form .button.secondary.cancelled {
  color: #db2828;
  border-color: #db2828;
}

.schedule-form .button.secondary.cancelled:hover {
  background-color: #db2828;
  color: white;
}
