.banner-items__wrap {
  background: #efefef;
}
.banner-items {
  position: relative;
}
.banner-items__image {
  cursor: pointer;
}
.banner-items__button {
  position: absolute;
  top: calc(50% - 14px);
  background-color: transparent;
}
.banner-items__button:focus {
  outline: 0;
}
.banner-items__button:hover {
  background-color: rgb(255 255 255 / 25%);
}
.banner-items__button:disabled {
  display: none;
}
.banner-items__button i.big.icon {
  margin: 0;
  line-height: 1;
  color: #535662;
}
.banner-items__button.back {
  left: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.banner-items__button.next {
  right: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ui.grid.banner-items__grid {
  height: 100%;
  padding-left: 3rem;
}
.ui.grid.banner-items__grid .column {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.grid.banner-items__grid button.ui.button {
  margin-top: 1rem;
}
.ui.grid.banner-items__grid button.ui.button.mobile {
  display: none;
}

/* BEGIN animations */
.banner-items .animation_custom {
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform;
}

.banner-items .slide_hidden {
  opacity: 0;
  transition: opacity 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.banner-items .slide_visible {
  opacity: 1;
  transition: opacity 0.9s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
/* END animations */

@media all and (max-width: 767px) {
  .ui.grid.banner-items__grid {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
  }
  .banner-items__grid .ui.basic.segment {
    padding-top: 0;
  }
  .ui.grid.banner-items__grid h1.ui.header {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .ui.grid.banner-items__grid h4.ui.header {
    display: none;
  }
  .ui.grid.banner-items__grid button.ui.button.mobile {
    display: block;
  }
  .ui.grid.banner-items__grid button.ui.button.desktop {
    display: none;
  }
}
