.ui.segment.checkout-form-container {
  margin-left: 3rem;
  margin-top: 0;
  padding-top: 0.5rem;
  max-width: 36rem;
  width: 100%;
}

.checkout-form-subheader {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.checkout-form-footer {
  margin: 1.5rem 0;
}

.checkout-form-promo-code label {
  display: block;
}

.checkout-form-input-text3 {
  max-width: 4rem;
}
.checkout-form-input-text4 {
  max-width: 5rem;
}
.checkout-form-submit {
  min-width: 100%;
}

.checkout-details-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.checkout-details-info .shipping {
  font-size: 1.1rem;
  line-height: 1.5;
}

.checkout-details-info-submit {
  margin-top: 2rem;
}

.checkout-details-info-delivery {
  margin-top: 1rem;
}

.checkout-details-info label {
  padding-left: 0;
}

.checkout-details-info textarea {
  margin-bottom: 0;
}
.checkout-details-info .ui.button.edit-shippable {
  margin-left: 1rem;
}

.edit-requisition-search-wrap {
  margin-top: 2rem;
}

.checkout-form-delivery-instructions {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .ui.segment.checkout-form-container {
    margin-left: 0;
    margin-top: 1rem;
  }
}
