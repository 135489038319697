.cleaning-review-form-am {
  display: inline-block;
  vertical-align: bottom;
}
.cleaning-review-form form {
  display: inline-block;
  padding: 1rem;
  border-radius: 0.25rem;
  max-width: 30rem;
}
.cleaning-review-stars-input {
  margin: 0.5rem 0;
}
.cleaning-review-form-stars {
  display: inline-block;
  vertical-align: middle;
}
.cleaning-review-form-stars-word {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  padding-left: 2rem;
}
.cleaning-review-star {
  display: inline-block;
  margin-right: 2px;
}
.cleaning-review-star-img {
  margin-right: 0.25rem;
}
.cleaning-review-star-img:hover {
  cursor: pointer;
}
.cleaning-review-star-img.star-empty {
}
.cleaning-review-comment-input {
  margin-top: 1rem;
}
.cleaning-review-comment-input label {
  padding-left: 0;
}
.cleaning-review-comment-input textarea {
  margin: 0;
}
.cleaning-review-submit-wrap {
  text-align: right;
}
