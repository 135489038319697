.ui.table.totals-table {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid white;
  margin-left: auto;
}

.spacer {
  padding: 0 2rem !important;
}

.dollar-value {
  color: #555c6e;
}

.total,
.label.total {
  color: #000;
  font-weight: bold;
}
