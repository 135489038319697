.fixed-width-column.external-vendor-invoices-list {
  margin: 1rem auto 20vh;
  margin-bottom: 0;
  padding-bottom: 3rem;
}
.external-vendor-invoices-list h2.ui.header {
  margin-top: 2rem;
}
.external-vendor-invoices-list
  .external-vendor-invoice-upload
  .ui.primary.button {
  display: none;
}
