.edit-budget-code-row {
  transition: all 1s;
}
.edit-budget-code-row .product-image,
.edit-budget-code-row .product-image {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.edit-budget-code-row .product-name {
  display: inline-block;
  vertical-align: middle;
  width: 80%;
}
.edit-budget-code-row.updated {
  box-shadow: 0 0px 10px RGBA(38, 167, 140, 0.5);
  background-color: RGBA(38, 167, 140, 0.5);
  transition: all 0.3s;
}
