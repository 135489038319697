.nav-location-switch__wrap {
  padding: 1rem 0.2rem;
}

.nav-location-switch__wrap.expanded {
  padding: 1rem;
}

.nav-location-switch {
  color: #535662;
  font-size: 1rem;
  background-color: rgb(245, 245, 245);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.ui.accordion:not(.styled)
  .title
  ~ .content:not(.ui).nav-location-switch__content {
  padding: 0;
  max-height: 160px;
  overflow-y: scroll;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.nav-location-switch__content::-webkit-scrollbar {
  display: initial;
  width: 8px;
  height: 8px;
  border-left: 1px solid #ccc;
}

.nav-location-switch__content::-webkit-scrollbar-thumb {
  background: #aaa;
}

.ui.accordion .title.nav-location-switch__title,
button.nav-location-switch__item,
button.nav-location-switch__item:hover,
button.nav-location-switch__item:active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  font-size: 1rem;
  padding: .5rem;
  text-align: left;
}

.nav-location-switch--collapsed {
  margin: auto;
  width: 40px;
  height: 61.19px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: white;
}

.nav-location-switch--collapsed i {
  margin: 0;
}

.ui.accordion .title.nav-location-switch__title {
  padding-right: 0.6rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  height: 61.19px;
}

.ui.accordion .title.nav-location-switch__title.active {
  border-bottom: 1px solid #ccc;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

button.nav-location-switch__item,
button.nav-location-switch__item:hover,
button.nav-location-switch__item:active {
  display: block;
  width: 100%;
  border-top: 1px solid #ccc;
  border-radius: 0;
  font-size: 1rem;
  line-height: 1;
  outline: none;
}

button.nav-location-switch__item:first-child {
  border-top: 0;
}

.nav-location-switch__title .nav-location-switch__name,
.nav-location-switch__title .nav-location-switch__address {
  text-align: left;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}

.nav-location-switch__name {
  text-transform: uppercase;
}

.nav-location-switch__address {
  color: #888;
  -webkit-font-smoothing: antialiased;
  margin-top: 0.2rem;
}

.nav-location-switch__title i {
  margin: 0;
  line-height: 1;
}

/** Animations for expanded text **/

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
