.supplies-order-budget-codes .supplies-order-container {
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.supplies-order-budget-codes .supplies-order-budget-codes-edit {
  flex-grow: 1;
  flex: 3;
  max-width: 50rem;
  margin-right: 2rem;
}
.supplies-order-budget-codes-edit .section-header {
  margin-bottom: 1rem;
}
.supplies-order-budget-codes-sidebar {
  flex: 1;
}
.supplies-order-budget-codes-sidebar .instructions {
  margin: 3rem 0 0 0;
}
.supplies-order-budget-codes-sidebar .budget-code-links-container {
  margin-top: 3rem;
}
