.ui.table.basic.supply-item-editable {
  border-radius: 0;
  background: white;
  border-top: 1px dashed #bcbdc1;
  margin-bottom: 0;
  cursor: move;
}
.supply-item-editable .product-item-image {
  line-height: 1;
}
.supply-item-editable .product-item-image img {
  height: auto;
  width: 55px;
}

button.no-style,
button.no-style:hover,
button.no-style:active,
button.no-style:visited {
  border: none;
  background-color: inherit;
  padding: 0;
}


.ui.table.basic.supply-item-editable:hover {
  background-color: #efefef !important;
}