.office-budget-settings .office-budget-settings-loader {
  width: 100%;
  margin: auto;
}

.office-budget-settings {
  padding: 0 0 1px 2rem;
  max-width: 22rem;
}
.office-budget-settings .ui.form {
  margin-bottom: 2rem;
}
.office-budget-settings .ui.form .field .ui.input input,
.office-budget-settings .ui.form .fields .field .ui.input input {
  width: 100%;
}

.office-budget-settings .ui.form .disabled {
  opacity: unset;
}

.office-budget-settings .office-budget-settings-form {
  max-width: 30rem;
  width: 20rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.office-budget-settings .office-budget-annual label,
.office-budget-settings .office-budget-monthly label,
.office-budget-settings .office-budget-per-employee-per-month label {
  position: relative;
}
.office-budget-settings .office-budget-monthly label {
  padding-left: 0;
}
.office-budget-settings .office-budget-annual label::before,
.office-budget-settings .office-budget-monthly label::before,
.office-budget-settings .office-budget-per-employee-per-month label::before {
  content: '$';
  position: absolute;
  left: -0.8rem;
  bottom: -2.2rem;
  font-size: 1rem;
  color: #bcbdc1;
}
.office-budget-settings img.employees-icon {
  display: inline-block;
  vertical-align: top;
  height: 2rem;
  margin-top: 1.5rem;
  margin-right: 0.5rem;
  margin-left: -1.2rem;
}
.office-budget-settings img.employees-icon + label {
  display: inline-block;
}
.office-budget-settings .office-budget-annual img {
  display: inline-block;
  vertical-align: top;
  height: 2rem;
  margin-top: 1.5rem;
  margin-right: 0.6rem;
  margin-left: -1.2rem;
}
.office-budget-settings .office-budget-annual label {
  display: inline-block;
}
.office-budget-settings .office-budget-annual label::before {
  bottom: 1.5rem;
  left: 0;
}
.office-budget-settings .office-budget-per-employee-per-month img {
  display: inline-block;
  vertical-align: top;
  height: 2rem;
  margin-top: 1.5rem;
  margin-right: 0.5rem;
}
.office-budget-settings .office-budget-per-employee-per-month label {
  display: inline-block;
}
.office-budget-settings .office-budget-per-employee-per-month label::before {
  bottom: 1.5rem;
  left: 0;
}

.office-budget-settings input.employees,
.office-budget-numbers-container input.annual,
.office-budget-numbers-container input.monthly,
.office-budget-settings input.per-employee-per-month {
  width: 6rem;
}
.office-budget-settings input:disabled {
  border-color: transparent;
  background-color: transparent;
  padding-left: 0;
  font-size: 1rem;
}
.office-budget-settings input::-webkit-outer-spin-button,
.office-budget-settings input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.office-budget-settings button {
  margin-top: 1rem;
}

.office-budget-annual,
.office-budget-monthly,
.office-budget-per-employee-per-month {
  display: inline-block;
}

.office-budget-monthly {
  margin-left: 3rem;
  vertical-align: top;
}

@media (max-width: 450px) {
  .office-budget-settings .office-budget-settings-form .flex-item {
    flex-basis: 100%;
  }
}
