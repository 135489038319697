.settings-user-attendant.fixed-width-column {
  margin: 2rem auto;
}
.settings-user-attendant .settings-user-attendant-loader {
  width: 100%;
  margin: auto;
}

.settings-user-attendant.flex-wrapper {
  margin-top: 2rem;
}
.settings-user-attendant .user-details-icon-wrap {
  width: 10rem;
  margin-left: 4rem;
  text-align: center;
}
.settings-user-attendant .user-details-icon {
  height: 10rem;
  width: 10rem;
  border-radius: 100%;
  background: #8f949f;
  margin-bottom: 1rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;
}
.settings-user-attendant .user-details-icon img:hover {
  cursor: pointer;
}
.settings-user-attendant .input-avatar-preview {
  width: 100%;
  height: 100%;
}
.settings-user-attendant .input-avatar-preview-placeholder {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.settings-user-attendant .user-details-form {
  max-width: 20rem;
  width: 20rem;
  margin-bottom: 4rem;
}

.settings-user-attendant input.floors {
  width: 5.5rem;
}

.settings-user-attendant input.avatar {
  display: none;
}

.settings-user-attendant button {
  margin-top: 2rem;
}

.settings-user-attendant .avatar-help-text {
  font-style: italic;
  color: #b4b4b4;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
.settings-user-attendant .settings-email {
  margin-bottom: 0;
}

.settings-user-attendant button.change-password-toggle.tertiary {
  display: block;
  margin: 2rem 0;
}
.settings-user-attendant .change-password {
  display: block;
  margin: 0 0 2rem;
}

@media (max-width: 758px) {
  .settings-user-attendant .user-details-icon {
    display: none;
  }
}

@media (max-width: 450px) {
  .settings-user-attendant .user-details-form .flex-item {
    flex-basis: 100%;
  }
}
