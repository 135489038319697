.insights-pdf-locations-data {
  margin-top: 2rem;
}
.insights-pdf-locations-data .insights-data {
  display: block;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 0.5rem 0;
}
.insights-pdf-locations-data h3.location-name {
  padding-bottom: 0.5rem;
}
.insights-pdf-locations-data .insights-data p.header {
  width: 14rem;
  display: inline-block;
}
.insights-pdf-locations-data .insights-data p.data {
  display: inline-block;
}
