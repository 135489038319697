.supply-item {
  border: none;
  padding: 0;
  box-sizing: border-box;
  border-radius: 0;
  border-top: 1px dashed #bcbdc1;
  width: 100%;
  background-color: white;
  position: relative;
}

.supply-item .item-product-details-container,
.supply-item .item-product-actions-container,
.supply-item .item-product-image,
.supply-item .item-product-details,
.supply-item .item-product-in-stock-status,
.supply-item .item-product-in-cart-status {
  display: inline-block;
  vertical-align: top;
}

.supply-item .item-product-details-container {
  width: 50%;
  padding: 1rem;
}

.supply-item .item-product-actions-container {
  width: 50%;
  padding: 0 1rem;
  background-color: white;
  position: relative;
}

.supply-item .item-product-image {
  width: 47%;
  margin-right: 0.5rem;
  cursor: pointer;
  position: relative;
}
.supply-item .item-product-image img {
  width: 100%;
  max-width: 144px;
  height: auto;
}

.supply-item .item-product-details {
  max-width: 45%;
  margin-left: 0.5rem;
  padding: 0.5rem 0;
}

.supply-item .item-product-in-stock-status {
  width: 40%;
  padding: 1rem 1rem 0 0;
}

.supply-item .item-product-in-cart-status {
  width: 60%;
  height: 100%;
  padding: 0.5rem 0 0.5rem 1rem;
}

.supply-item .item-product-in-cart-status .item-last-ordered-title {
  font-size: 12px;
}

.supply-item .item-product-details-container .item-product-details .item-name {
  cursor: pointer;
}

.supply-item
  .item-product-details-container
  .item-product-details
  .item-product-manufacturing-number {
  display: none;
}

.price-shipping {
  display: flex;
  align-items: center;
}

.price-shipping .product-price {
  font-weight: 600;
}

.price-shipping .shipping-on-image {
  display: flex;
  align-items: center;
  margin-left: 0.7rem;
  color: #f18770;
}

.supply-item .big-box-wrap {
  margin-left: 0.4rem;
}
.supply-item .item-catalogable {
  top: 7px;
  right: calc(50% - 65px);
}

@media (max-width: 992px) {
  .supply-item .item-product-image .product-item-image img {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 450px) {
  .supply-item {
    max-height: 100%;
    margin-right: 0;
  }

  .supply-item .item-product-details-container {
    width: 100%;
    display: flex;
    padding: 1rem 1rem 0;
  }
  .supply-item .item-product-actions-container {
    width: 100%;
    display: flex;
    padding: 0 0.5rem 1rem;
    border-left: none;
  }

  .supply-item .item-product-actions-container .item-product-in-stock-status,
  .supply-item .item-product-actions-container .item-product-in-cart-status {
    width: 50%;
    padding: 0 0.25rem;
  }

  .supply-item .item-product-details {
    max-width: 100%;
  }

  .supply-item .item-product-actions-container .item-product-in-cart-status {
    border: none;
  }

  .supply-item .item-product-image {
    width: 40%;
    padding: 0 1rem;
    display: block;
    margin: 0;
  }

  .supply-item .item-product-details {
    width: 100%;
    padding: 0 1rem;
    display: block;
    margin: 0;
  }

  .supply-item .item-product-details .flex-wrapper,
  .supply-item .item-product-details .item-product-units {
    display: block;
    margin: 0;
  }

  .supply-item .mobile-item-details {
    border-top: 1px solid #bcbdc1;
    padding: 1rem 0;
    margin-top: 1rem;
  }

  .supply-item .item-product-image .product-item-image img {
    height: 144px;
    width: 144px;
  }
  .supply-item .item-catalogable {
    right: calc(50% - 75px);
  }
}

@media (max-width: 400px) {
  .supply-item .item-product-image .product-item-image img {
    height: 100px;
    width: 100px;
  }
  .supply-item .item-catalogable {
    right: calc(50% - 45px);
  }
}
