.modal .modal-shipment .shipment-status {
  text-transform: capitalize;
}
.modal .modal-shipment .shipment-products {
  margin: 3rem 0;
}
.modal .modal-shipment .shipment-products tr {
  position: relative;
}
.modal .modal-shipment .shipment-products tr td.right.aligned {
  font-size: 1.5rem;
  font-weight: lighter;
}
.modal .modal-shipment .shipment-products tr td.right.aligned.show-mobile {
  font-size: 1.5rem;
  font-weight: lighter;
  position: absolute;
  top: 1.4rem;
  right: 0.5rem;
}
.modal-shipment .actions-dropdown {
  text-transform: capitalize;
  font-weight: bold;
  color: #00a98c;
}
.modal-shipment .actions-dropdown.missed,
.modal-shipment .ui.dropdown .menu .actions-dropdown-item.missed {
  color: darkorange;
  font-size: inherit;
}
.modal-shipment .actions-dropdown.received,
.modal-shipment .ui.dropdown .menu .actions-dropdown-item.received {
  color: #00a98c;
  font-size: inherit;
}
.modal-shipment .action-timestamp {
  padding-left: 0.6rem;
  color: darkgrey;
  font-weight: bold;
}

@media (max-width: 630px) {
  .modal .modal-shipment {
    padding: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    max-height: 100vh;
  }
}

button.modal-shipment.modal-close.close-icon {
  text-indent: -10000px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.modal-shipment .item-expected-data {
  margin-bottom: 1rem;
  width: 60%;
  display: inline-block;
  vertical-align: top;
}
.modal-shipment .item-shipping-data {
  width: 40%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1rem;
}
.modal-shipment .item-shipping-data .requisitions-links-container {
  margin-top: 1rem;
}

.modal-shipment .item-count {
  padding-left: 0.5rem;
}
.modal-shipment .item-expected-data .expected-at-date,
.modal-shipment .item-expected-data .item-vendor-number,
.modal-shipment .item-expected-data .item-tracking-data {
  margin-bottom: 0.5rem;
}
.modal-shipment .item-expected-data .data-title {
  display: block;
}

.modal-shipment .shipment-submit-comment-link {
  max-width: 30rem;
  text-align: center;
  margin: 1rem auto;
}

.modal-shipment .shipment-submit-comment-link button.secondary {
  margin: 0 auto 1rem;
}

.modal-shipment .shipment-comments {
  margin: 2rem;
  padding-left: 2rem;
}

.modal-shipment .shipment-comments .submitted-comment-body {
  margin-bottom: 2rem;
}

.modal-shipment .item-requisition-links .requisition-link {
  display: inline-block;
  margin-right: 0.5rem;
}

.modal-shipment .item-requisition-links .requisition-link .button.tertiary {
  padding-left: 0;
  font-weight: bold;
}

.modal-shipment-order-links {
  font-weight: inherit;
  font-family: inherit;
}
.modal-shipment-order-links a {
  display: inline-block;
  padding-right: 0.5rem;
}
.modal-shipment-order-links a::after {
  display: inline-block;
  content: ',';
  color: #535662;
}
.modal-shipment-order-links a:last-child::after {
  display: none;
}

@media (max-width: 450px) {
  .modal-shipment .item-expected-data,
  .modal-shipment .item-shipping-data {
    width: 100%;
    display: block;
  }
  .modal-shipment .item-shipping-data {
    padding-left: 3rem;
  }
}
