.four-oh-four {
  height: 100vh;
  padding: 10vh 2rem;
}
.four-oh-four-wrap {
  text-align: center;
  border-top: #00a98c solid 4px;
  background: white;
  margin: 0 auto;
  max-width: 25rem;
  padding: 1rem 1rem 3rem;
}
.four-oh-four-title {
}
.four-oh-four-body {
  margin: 2rem 2rem 3rem;
}
.four-oh-four-body code {
  font-family: monospace;
  background: #eee;
  padding: 0.1rem;
}
