.cleaning-staffs-list.flex-wrapper {
  justify-content: flex-start;
  padding-bottom: 1rem;
}

.cleaning-staffs-list .staff-avatar {
  display: inline;
  text-align: center;
  position: relative;
}

.cleaning-staffs-list .staff-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 0.5rem;
  margin-right: 2rem;
  line-height: 1.25rem;
}

.cleaning-staffs-list .staff-name-clocked-in {
  display: block;
  vertical-align: middle;
}

.cleaning-staffs-list .staff {
  min-width: 15rem;
}

.cleaning-staffs-list .staff-name-clocked-in {
  color: #00a98c;
  font-weight: 700;
}
