.modal-delivery-info {
  max-width: 80%;
  margin: 2rem auto;
  max-height: calc(100% - 4rem);
  padding: 0;
}
.modal-delivery-info .content-panel .logo {
  display: none;
}
.modal-delivery-info .content-panel .step-marker {
  display: none;
}
