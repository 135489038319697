.calendar-view {
  padding: 2rem 1rem !important;
}

.calendar-view__container {
  display: flex;
}

.calendar-view__left {
  flex: 1;
  margin-right: 1rem;
}

.calendar-view__right {
  flex: 1;
  min-width: 500px;
}

@media (max-width: 980px) {
  .calendar-view__container {
    flex-direction: column-reverse;
  }

  .calendar-view__left {
    margin-right: 0;
  }

  .calendar-view__right {
    margin-top: 0;
    min-width: 0;
    margin-bottom: 2rem;
  }
}
