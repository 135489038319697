.modal-invoice-save-container {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.modal-invoice-pdf {
  position: relative;
  padding: 1.5rem 1rem 0.75rem 1rem;
}

.modal-invoice-pdf object {
  width: 100%;
  max-height: 100%;
}

.invoice-form-header-container {
  display: inline-block;
}

.invoice-checkmark.main-icon {
  display: inline-block;
  margin-right: 1rem;
}
h3.invoice-form-header {
  display: inline-block;
}

h2.modal-invoice-header-text {
  display: inline-block;
  width: calc(100% - 2rem);
}

button.modal-invoice.modal-close.close-icon {
  display: inline-block;
  text-indent: -10000px;
  vertical-align: middle;
  float: right;
}
