.tasks-form.subpage-component {
  margin-top: 10rem;
}

.archived-tasks-list.container {
  margin-top: 10rem;
}

.office-attendant-cleaning-schedule .fixed-width-column {
  padding: 1rem;
}

@media (max-width: 450px) {
  .tasks-form.subpage-component {
    margin-top: 3rem;
  }

  .archived-tasks-list.container {
    margin-top: 3rem;
  }
}
