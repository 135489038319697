.rrule-input {
  padding: 15px 5px;
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-right: 10px;
}
.rrule-input .form-group {
  margin-bottom: 1rem;
}
.rrule-input .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.rrule-input .d-flex {
  display: flex;
}
.rrule-input .align-items-sm-center {
  align-items: center;
  padding: 0 12px;
}
.rrule-input div[class*='col-'] {
  position: relative;
  padding-right: 15px;
}
.rrule-input .col-sm-1 {
  /*max-width: 9.333333%;*/
  min-width: 33px;
}
.rrule-input .col-sm-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.rrule-input button,
.rrule-input select {
  text-transform: none;
}
.rrule-input select:disabled {
  background: #e4e4e4;
}
.rrule-input .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.rrule-input input,
.rrule-input select {
  outline: none;
}
.rrule-input strong {
  font-weight: normal;
}
.rrule-input input.form-control,
.rrule-input #rrule-1-repeat-daily-interval {
  width: 55px;
  border: 1px solid #c9d0d6;
  font-size: 0.95em;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  outline: none;
  padding: 8px 10px 7px;
}
.rrule-input select.form-control:not([size]):not([multiple]) {
  height: auto;
  margin-top: -0.1rem;
  margin-bottom: 0;
  appearance: none;
  padding: 2px 0.75rem;
}
.rrule-input
  select.form-control[name='repeat.frequency']:not([size]):not([multiple]) {
  margin-top: -0.25rem;
}
.rrule-input .btn-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  vertical-align: middle;
  padding-left: 15px;
  flex-wrap: wrap;
}
.rrule-input .btn-group label {
  padding-left: 0;
  text-transform: capitalize;
  max-width: 50px;
}

.rrule-input .human-text {
  font-style: italic;
  padding-left: 15px;
}

@media all and (max-width: 768px) {
  .rrule-input {
    width: 100%;
  }
}
