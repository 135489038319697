.budget-code-spend-line {
  position: relative;
}
.budget-code-spend-line .loader-wrapper {
  position: absolute;
  top: 50%;
  top: calc(50% - 20px);
  left: 50%;
  left: calc(50% - 16px);
}
.total-spend-tooltip text tspan {
  line-height: 2rem !important;
}
.budget-code-spend-line text.total-spend-line-label tspan {
  display: none !important;
}

@media print {
  .budget-code-spend-tooltip {
    display: none !important;
  }
  .insights-category-chart .budget-code-spend-line {
    width: 100% !important;
    min-height: 370px;
  }
  .budget-code-spend-line text.total-spend-line-label tspan {
    display: block !important;
    fill: black !important;
    font-size: 0.5rem !important;
  }
  .budget-code-spend-line text.total-spend-x-axis-label tspan {
    font-size: 0.5rem !important;
  }
}
