.select select {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-color: white;
  background-image: url('./../../imgs/down-arrow-icon--gray.svg');
  background-size: 0.8rem;
  background-position: calc(100% - 0.5rem);
  background-repeat: no-repeat;
  border-radius: 0;
  border: 1px solid #d8dade;
  outline: none;
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 0.875rem;
  display: block;
  box-sizing: border-box;
}
