.item-catalogable {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: white;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.item-catalogable.included {
  background-color: #00a98c;
}

.item-catalogable__item {
  font-size: 1.1rem;
}

.item-catalogable__item.included {
  color: white;
  font-size: 0.8rem;
}

.item-catalogable__modal {
  padding: 1.5rem;
  box-sizing: border-box;
}

.item-catalogable__actions {
  display: flex;
  justify-content: space-around;
}

.item-catalogable__product-desc > p {
  text-align: center;
  margin-bottom: 2rem;
}

.item-catalogable__product-image {
  padding: 1rem 0;
}

.item-catalogable__product-name {
  text-align: center;
}
