.calendar-agenda-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid #efefef;
}

.calendar-agenda-item:first-child {
  border-top: none;
}

.calendar-agenda-item p,
.calendar-agenda-item strong {
  font-size: 1rem;
}

.calendar-agenda-item__left {
  display: flex;
  align-items: center;
}

.calendar-agenda-item__left > div,
.calendar-agenda-item__left > i {
  margin: 0 0.5rem;
}

i.icon.calendar-agenda-item__icon {
  display: inline-block;
  font-size: 1.1rem;
  width: 1rem;
  min-width: 1rem;
}

div.calendar-agenda-item__icon {
  display: inline-block;
  border: 2px solid grey;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.calendar-agenda-item__right {
  text-align: right;
  min-width: 120px;
}

.calendar-agenda-item__right button.ui {
  width: 100%;
  margin: 0;
  padding: 0.5rem 0.2rem;
}

.calendar-agenda-item__subtitle {
  font-style: italic;
}
