.service-schedule {
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

.service-schedule {
  box-sizing: border-box;
}

.service-schedule {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  /* background: var(--bg-color); */
  position: relative;
}

.service-schedule header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

.service-schedule .header .angle {
  font-size: 2.5em;
  color: #00a98c;
}

.service-schedule .header .week {
  font-size: 1.1rem;
}

.service-schedule .header hr {
  background-color: #00a98c;
  height: 2px;
  border: none;
}

.service-schedule .header .angle:hover {
  cursor: pointer;
}

.service-schedule header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

.service-schedule header #logo .icon {
  padding-right: 0.25em;
}

.service-schedule {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 90em;
}

.service-schedule .row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.service-schedule .row-middle {
  align-items: center;
}

.service-schedule .col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.service-schedule .col-start {
  justify-content: flex-start;
  text-align: left;
}

.service-schedule .col-center {
  justify-content: center;
  text-align: center;
}

.service-schedule .col-end {
  justify-content: flex-end;
  text-align: right;
}

.service-schedule .calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.service-schedule .calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.service-schedule .calendar .header .icon {
  transition: 0.15s ease-out;
}

.service-schedule .calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.service-schedule .calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.service-schedule .calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.service-schedule .calendar .body .cell {
  position: relative;
  min-height: 12em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  font-size: 1.5em;
  padding-top: 30px;
}

.service-schedule .calendar .body .cell .timeslot {
  padding: 5px;
  border-radius: 5px;
  border-color: gray;
  border-style: solid;
  border-width: 1px;
  margin: 10px;
}

.service-schedule .calendar .body .cell .timeslot img {
  height: 0px;
  border-radius: 4px;
  border: black 1px solid;
  display: none;
}

.service-schedule .calendar .body .today .timeslot img {
  height: 2rem;
  display: block;
}

.service-schedule .calendar .body .today .timeslot {
  border-color: aquamarine;
}

.service-schedule .calendar .body .today {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #00a98c 0%, paleturquoise 40%);
  border-image-slice: 1;
}

.service-schedule .calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.service-schedule .calendar .body .cell:last-child {
  border-right: none;
}

.service-schedule .calendar .body .cell .name {
  font-weight: 700;
}

.service-schedule .calendar .body .cell .title {
  font-style: italic;
}

.service-schedule .calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.service-schedule .calendar .body .cell .day-of-week {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  left: 0.75em;
  font-weight: 700;
  visibility: hidden;
}

.service-schedule .calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.service-schedule .calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

@media screen and (max-width: 992px) {
  .service-schedule .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 1);
    width: calc(100% / 1);
  }

  .service-schedule .days.row {
    visibility: hidden;
    height: 0px;
  }

  .service-schedule .calendar .body .cell .day-of-week {
    visibility: visible;
  }
}
