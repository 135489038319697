.supplies-orders .flex-wrapper {
  justify-content: flex-start;
}

.supplies-orders .orders-box {
  width: 25rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
  flex-grow: 1;
  flex-shrink: 1;
}

@media (max-width: 1200px) {
  .supplies-orders .requisitions {
    margin-left: 0;
  }
}

@media (max-width: 450px) {
  .supplies-orders .orders-box {
    margin-right: 0;
  }
}
