.requisition-cart {
  background-color: #fff;
  padding: 1rem 0;
  width: 20rem;
  border-top: 1px solid #535662;
}
.requisition-cart.edit-mode {
  box-shadow: none;
}
.requisition-cart .shipping-line-item {
  margin-left: 0;
}
.requisition-cart .shipping-line-item:last-child {
  margin-bottom: 1rem;
}

.requisition-cart .shipping-title {
  display: inline-block;
  width: 60%;
}

.requisition-cart .no-min-header {
  margin-left: 1rem;
  font-weight: normal;
  text-transform: uppercase;
}

.requisition-cart .shipping-fee {
  display: inline-block;
  width: 40%;
  text-align: right;
}
.requisition-cart .shipping-line-item .microcopy {
  text-align: center;
  margin-top: 1rem;
  font-weight: 700;
}

@media (min-width: 430px) {
  .requisition-cart.with-replacement-options {
    width: 27rem;
  }
}

@media (max-width: 768px) {
  .requisition-cart {
    width: 100%;
  }
}

.cart-header {
  display: flex;
  justify-content: space-between;
  margin: -1rem 0 0;
  padding: 0.8rem;
  text-align: right;
  border-bottom: 1px dashed #bcbdc1;
  background-size: 2rem;
  background-position: 1rem;
  background-position-y: 0.5rem;
  background-repeat: no-repeat;
}
.cart-header.with-icon {
  display: block;
  background-image: url(../../imgs/icon-cart--black.svg);
}
.cart-header .cart-title {
  display: inline-block;
  vertical-align: text-bottom;
  text-transform: uppercase;
  margin-right: 1rem;
}

.cart-header.with-icon .cart-title {
  margin-right: 0;
}

.cart-items {
  max-height: calc(100vh - 17rem);
  overflow: auto;
}

.cart-items.expanded {
  max-height: initial;
}
.cart-footer {
  padding: 1rem;
  border-top: 1px dashed #bcbdc1;
}
.cart-header-totals {
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
  border-bottom: 1px dashed #bcbdc1;
}
.cart-total {
  border-top: 1px dashed #bcbdc1;
  padding-top: 0.25rem;
}
.cart-subtotal-subhead {
  margin: 0 auto 1rem;
  display: inline;
}

.cart-checkout-button-wrap {
  margin-top: 0.5rem;
}
.ui.button.cart-checkout-button {
  width: 100%;
}

.cart-header .cart-header-right {
  display: flex;
  align-items: center;
}

.cart-header.with-icon .cart-header-right {
  display: block;
}

@media (max-width: 450px) {
  .cart-header {
    background-position: 2.5rem;
  }
}
