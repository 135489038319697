.checkout-page {
  margin-top: 1rem;
  padding-bottom: 50vh;
}

.checkout-page .flex-wrapper {
  align-items: flex-start;
}

.checkout-page .flex-wrapper.centered {
  justify-content: center;
}

.checkout-page__col-right {
  margin-left: 3rem;
  margin-top: 0;
  width: 100%;
  max-width: 27rem;
}

.checkout-page .cart-subtotal-subhead-container h4 {
  margin-left: 0;
}

.checkout-page .requisition-cart {
  box-shadow: none;
}

.checkout-page-error {
  margin-top: 1.5rem;
  margin-left: 0.75rem;
  color: darkred;
}

.button.tertiary.checkout-page-scroll-to-search {
  padding-left: 0;
  margin-bottom: 1rem;
  display: block;
}

@media (max-width: 768px) {
  .checkout-page__col-right {
    margin-left: 0;
    margin-top: 1rem;
  }
}
