.edit-requisition-search-wrap .product-search {
  max-width: 680px;
  margin: auto;
}

.edit-requisition-search-wrap .subheader.bold-text {
  display: none;
}

.product-search .react-autosuggest__container {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 60px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.product-search {
  width: 100%;
}
.product-search .react-autosuggest__input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  vertical-align: top;
  margin-bottom: 0;
  padding-left: 60px;
  height: 100%;
  font-size: 1.3rem;
  font-weight: 300;
  border-radius: 0;
}
.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  position: absolute;
  width: 100%;
  background-color: white;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  z-index: 3;
  margin-top: -3px;
  word-break: break-all;
}
.react-autosuggest__suggestions-list {
  list-style: none;
}
.react-autosuggest__suggestion {
  padding: 0.5rem;
}
.react-autosuggest__suggestion:hover {
  cursor: pointer;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #f8faf9;
}
.react-autosuggest__suggestion .see-all-results {
  color: #02b4c5;
}
.product-search-submit {
  display: inline-block;
  width: 3rem;
  padding: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  vertical-align: top;
  margin-top: 2px;
  border: none;
}

.product-search-submit.button-with-icon {
  position: absolute;
  left: 0.1rem;
  height: 2rem;
  margin-top: 1.2rem;
  z-index: 1;
  text-indent: -2rem;
  color: transparent;
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: 0.5rem 0;
  background-color: #fff;
  border-radius: 0;
  border: none;
  border-right: solid 1px lightgray;
}

.product-search-form {
  position: relative;
}

@media (max-width: 760px) {
  .edit-requisition-search-wrap .product-search {
    max-width: none;
  }
}

@media (max-width: 450px) {
  .product-search {
    width: calc(100% - 60px);
    padding: 0.5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .product-search .react-autosuggest__container {
    width: calc(100% - 3.5rem);
    height: 40px;
    margin: 0;
  }

  .product-search .react-autosuggest__input {
    padding-left: 1rem;
    font-size: 1rem;
  }

  .product-search-submit {
    width: 3.5rem;
  }

  .product-search-submit.button-with-icon {
    top: 0;
    right: 0;
    left: unset;
    margin: 0;
    height: 40px;
    background-image: url('./../../imgs/icon-magnify--white.svg');
    background-position: 0.7rem 0.2rem;
    border: none;
  }
}
