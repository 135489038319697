.show-when-printing {
  display: none;
}
.print-pdf {
  display: none;
}

@media print {
  body {
    background-color: white;
  }
  .hide-when-printing {
    display: none !important;
  }

  .show-when-printing {
    display: block !important;
  }

  .insights-budgets-container,
  .insights-budget-spend .total-office-spend-line,
  .insights-budget-spend .hide-when-printing.office-budget-settings-container,
  .insights-category-spend {
    display: block;
  }

  div.insights-category-spend {
    page-break-inside: avoid;
    min-height: 270px;
  }

  div.total-office-spend-line div.total-office-spend-chart {
    page-break-inside: avoid;
  }
}
