.cleaning-task-form-header-container {
  display: inline-block;
}

.cleaning-task-checkmark.main-icon {
  display: inline-block;
  margin-right: 1rem;
}
h3.cleaning-task-form-header {
  display: inline-block;
}

h2.modal-cleaning-header-text {
  display: inline-block;
  width: calc(100% - 2rem);
}

@media (max-width: 768px) {
  .modal-cleaning-task-header {
    padding-bottom: 0.5rem;
    margin-bottom: 0;
  }
}
