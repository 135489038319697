.edit-budget-code-select {
  display: inline-block;
  margin-right: 2rem;
  height: 3.6rem;
  vertical-align: bottom;
}
.create-budget-code {
  display: inline-block;
  vertical-align: middle;
}
.edit-budget-code-products .select-all {
  margin: 1rem 0;
}
.edit-budget-code-products-header.flex-wrapper {
  text-align: middle;
  padding: 1rem;
  font-weight: bold;
}
.edit-budget-code-products-header .select-header {
  flex: 0.25;
  margin-right: 1rem;
}
.edit-budget-code-products-header .product-header {
  flex: 2;
}
.edit-budget-code-products-header .budget-code-header {
  flex: 1;
}
.edit-budget-code-products-list .input-checkbox:hover,
.edit-budget-code-products-list input[type='checkbox'] {
  cursor: pointer;
}
.edit-budget-code-products-list .budget-row:nth-child(odd),
.edit-budget-code-products-list .budget-row:nth-child(odd) {
  background-color: #f3f4f5;
}
