.cleaning-comment.commenter-avatar {
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 1.25rem;
  width: 36px;
}

.cleaning-comment.commenter-avatar.staff {
  width: 36px;
  margin-left: 0.5rem;
}

.cleaning-comment.comment-details-container {
  display: inline-block;
  max-width: calc(100% - 50px);
}

.cleaning-comment-container.staff {
  /* text-align: right; */
}

.cleaning-comment-container.staff .cleaning-comment.body {
  border: 1px solid #00a98c;
  /* background-color: #E2F4F1; */
  border-radius: 0.25rem;
  padding: 0.5rem;
  padding-top: 0;
  text-align: left;
}

.cleaning-comment.commenter-name {
  padding-left: 0.5rem;
  text-align: left;
}

.cleaning-comment.date {
  text-align: right;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
