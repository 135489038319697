.cleaning-comments-list-container .list-header img {
  height: 2.127rem;
  margin-right: 0.5rem;
  vertical-align: bottom;
  margin-left: 0.75rem;
}

.cleaning-comments-list-container h3 {
  display: inline-block;
  vertical-align: bottom;
}

.cleaning-comments-list-container .avatar svg,
.cleaning-comments-list-container .avatar img {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  border-width: 2px;
}

.notes-empty-state .microcopy {
  margin-top: 1rem;
  text-align: left;
  font-style: italic;
  padding-bottom: 1rem;
}

.notes-empty-state .cleaning-comment-container {
  margin-top: 1.5rem;
  display: flex;
}

.notes-empty-state .cleaning-comment-container.staff .cleaning-comment.body {
  border: none;
  color: #535661;
}

.shift-notes .completed-empty-state {
  color: #8f949f;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.notes-empty-state .cleaning-comment-container.employee .cleaning-comment.body {
  border: none;
  color: #535661;
}

.notes-empty-state .cleaning-comment.commenter-avatar {
  vertical-align: top;
  padding-bottom: 0;
  align-self: flex-start;
}

.cleaning-comments-list-container .shift-notes {
  background-color: white;
  padding: 1.5rem;
  padding-bottom: 0;
}

.cleaning-comments-list-container .shift-notes .site-h4 {
  font-size: 1rem;
}
