.todays-orders-section {
  border-bottom: 0.5rem solid white;
  position: relative;
}
.todays-orders-section-text {
  margin-bottom: 0.5rem;
  font-size: 22px;
  letter-spacing: 0.05rem;
  line-height: 2rem;
}

.todays-orders-empty-state {
  margin-bottom: 3rem;
}

.todays-orders-section .notification-text {
  font-weight: 700;
  text-transform: uppercase;
}

.close-btn {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  padding: 0;
  background-color: transparent;
  transform: scale(0.75);
}
.close-btn:hover {
  background-color: transparent;
}

@media (max-width: 450px) {
  .todays-orders-section {
    padding: 1rem;
  }

  .todays-orders-section-text {
    font-size: 14px;
  }
}
