.total-office-spend-line {
  margin-bottom: 2rem;
}
.total-spend-header {
  margin: 1rem;
}
.total-spend-header:first-child {
  margin-bottom: 0;
}
.total-spend-header span {
  display: inline-block;
  margin-right: 1rem;
}
.total-spend-header strong {
  display: inline-block;
  font-size: 1rem;
}
.total-office-spend-chart {
  position: relative;
}
.total-office-spend-chart .loader-wrapper {
  position: absolute;
  top: 50%;
  top: calc(50% - 20px);
  left: 50%;
  left: calc(50% - 16px);
}
.total-office-spend-chart text.total-spend-line-label tspan {
  display: none !important;
}
.total-spend-tooltip text tspan {
  line-height: 2rem !important;
}

@media print {
  .total-spend-tooltip {
    display: none !important;
  }
  .insights-budget-spend .total-office-spend-line {
    width: 100% !important;
    min-height: 370px;
  }
  h3.total-spend-header.total-office-spend {
    margin-top: 1rem;
  }
  .total-office-spend-chart text.total-spend-line-label tspan {
    display: block !important;
    fill: black !important;
    font-size: 0.5rem !important;
  }
  .total-office-spend-chart text.total-spend-x-axis-label tspan {
    font-size: 0.5rem !important;
  }
  .total-office-spend-line div.aggregate-spend-data.print-pdf {
    display: block;
  }
  .total-office-spend-line div.aggregate-spend-data.print-pdf.multiple {
    margin-bottom: 2rem;
  }
  .total-office-spend-line h3.multiple-locations-header {
    margin-top: 1.5rem;
  }
  .total-office-spend-line div.insights-data-row {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 0.5rem 0;
  }
  .total-office-spend-line div.insights-data-row p.header.bold-text {
    width: 14rem;
    display: inline-block;
  }
  .total-office-spend-line div.insights-data-row p.data {
    display: inline-block;
  }
}
