.settings-team-row .multi-select {
  width: 100%;
  min-width: 12rem;
}
.ui.basic.table .settings-team-row td {
  vertical-align: middle;
}
.settings-team .ui.basic.table tr:nth-child(2) td {
  border-top: 1px solid #bcbdc1;
}
.settings-team-row .employee-details-container {
  display: flex;
}
.settings-team-row .employee-details-container .avatar-container {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .settings-team-row .multi-select {
    min-width: 0;
  }
}
