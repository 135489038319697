html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: Roboto, Helvetica, sans-serif;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  line-height: 1.4rem;
  font-style: normal;
  font-weight: 400;
  color: #535662;
  /* background-color: #f8f8f7; */
}

body,
div,
p,
input,
strong,
blockquote,
a,
form,
button,
table {
  font-size: 0.875rem; /* 14px */
  box-sizing: border-box;
}

/* TYPOGRAPHY */
body {
  /* background-color: #f8f8f7; */
  min-height: 100vh;
}
sup {
  vertical-align: super;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: Poppins, Roboto, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.2;
}
h1 {
  font-size: 3.625rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.625rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 0.875rem;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
h6 {
  font-size: 1rem;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 700;
}
p {
  line-height: 1.4;
}
kbd {
  font-family: monospace;
  background: #eee;
  padding: 3px 6px;
  box-shadow: 0px 0px 1px;
  border-radius: 2px;
  margin: 3px;
  vertical-align: bottom;
}
select {
  font-size: 1rem;
}
select::-ms-expand {
  display: none;
}
input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
textarea {
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1rem;
  font-family: inherit;
  border: 1px solid #bcbdc1;
  background-color: white;
  line-height: 1.25rem;
  box-sizing: border-box;
  color: #535662;
}

input[type='text']:hover,
input[type='email']:hover,
input[type='password']:hover,
input[type='number']:hover,
textarea:hover {
}

input[type='text']:focus,
input [type='email']:focus,
input [type='password']:focus,
input[type='number']:focus,
textarea:focus {
  border-color: #00a98c;
  outline: none;
}

input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='number']:placeholder,
textarea::placeholder {
  color: #bcbdc1;
  font-weight: 300;
  font-style: italic;
}

.card {
  background-color: white;
  border-top: 2px solid #535662;
  padding: 1.5em;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12);
}

textarea:disabled,
input:disabled {
  background-color: #f1f1f1;
}
label {
  font-weight: bold;
}
label input {
  display: block;
}
a {
  color: #00a98c;
  text-decoration: none;
}
a.primary {
  font-weight: 700;
  letter-spacing: 0.03em;
}
button:hover {
  cursor: pointer;
}
.microcopy {
  font-size: 0.875rem;
  color: #999daa;
  line-height: 1.25rem;
}
.bold-text,
strong {
  font-weight: 700;
}
.italic-text {
  font-style: italic;
}
.uppercase-text {
  text-transform: uppercase;
}

/* SEMANTIC-UI OVERRIDES */
.ui.segment.attached,
.ui.segment {
  border: none;
  box-shadow: none;
  border-radius: 0px;
}
.ui.segment.unattached {
  background: none;
}
.ui.placeholder.segment {
  background: white;
  box-shadow: none;
}
.ui.header.attached,
.ui.header {
  border: none;
  border-radius: 0px;
}
.ui .header.light-gray-text {
  color: #bcbdc1 !important;
}
.ui .header.gray-text {
  color: #999daa !important;
}
.ui.segment.attached.top,
.ui.header.attached.top {
  border-top: 1px solid #535662;
  border-radius: 0px;
}
.ui.segment.attached.bottom,
.ui.header.attached.bottom {
  border-radius: 0px;
}
.ui.header,
.ui.list > .item .header,
.ui.accordion .title:not(.ui),
.ui.message .header {
  letter-spacing: normal;
  font-family: Poppins, Roboto, Helvetica, sans-serif;
  color: inherit;
}
.ui.list .list > .item .header,
.ui.list > .item .header {
  margin-bottom: 0.25rem;
}
.ui.breadcrumb a {
  color: #00a98c;
  text-decoration: none;
}
.ui.breadcrumb a:hover {
  color: #00a98c;
  text-decoration: underline;
}
.ui.form .field > label {
  padding-left: 0;
  line-height: normal;
}

.hello span {
  color: #00a98c;
}

i.huge.icon {
  font-size: 3em;
}

.ui.button,
.ui.button.basic {
  font-family: inherit;
  border-radius: 7px;
  letter-spacing: 0.05em;
}
.ui.buttons .button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ui.buttons .button:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.ui.primary.button {
  background-color: #00a98c;
}
.ui.secondary.button {
  background-color: transparent;
  color: #00a98c;
  border: 1px solid #00a98c;
}
.ui.secondary.button.white-background {
  background-color: white;
  color: #00a98c;
  border: 1px solid #00a98c;
}
.ui.tertiary.button {
  background-color: transparent;
  color: #00a98c;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
.ui.positive.button {
  background-color: #00a98c;
}
.ui.primary.button:hover,
.ui.primary.button:focus,
.ui.secondary.button.active,
.ui.secondary.button:hover,
.ui.secondary.button:focus,
.ui.secondary.button.white-background:hover,
.ui.secondary.button.white-background:focus {
  background-color: #00a98c;
  color: white;
}
.ui.primary.button a,
.ui.secondary.button a {
  color: inherit;
  padding: 0.78571429em 1.5em 0.78571429em;
  margin: 0 -1.5rem;
}

.ui.positive.button:hover {
  background-color: #00a98c;
  transition: background-color 0.15s ease-in;
}
.ui.button.borderless {
  border: none !important;
}
.ui.buttons .or.page-bg:before {
  /* background-color: #f8f8f7; */
  border: 1px solid #00a98c;
}

.ui.attached.message,
.ui.message {
  border-radius: 0;
  box-shadow: none !important;
  border-top: 1px solid #999daa;
  background: white;
  color: inherit;
}
.ui.positive.attached.message,
.ui.positive.message,
.ui.success.attached.message,
.ui.success.message {
  border-top: 1px solid #00a98c;
  color: #00a98c;
  background-color: #fafdfc;
}
.ui.positive.message .header,
.ui.success.message .header {
  color: #00a98c;
  letter-spacing: 0.05em;
}
.ui.negative.message,
.ui.error.message {
  border-top: 1px solid #912d2b;
}
.ui.warning.message {
  border-top: 1px solid #794b02;
}
.ui.info.message {
  border-top: 1px solid #276f86;
  background: white;
}

.ui.table {
  color: inherit;
  font-size: 0.875rem;
}
.ui.table thead th {
  border-bottom: 1px dashed #bcbdc1;
  color: #999daa;
}
.ui.table tfoot th,
.ui.table tr td {
  border-top: 1px dashed #bcbdc1;
}
.ui.basic.table tbody tr {
  border-bottom: none;
}
.ui.table.basic {
  border: none;
}
.ui.compact.table th {
  padding-bottom: 0.5em;
}
.ui.table.basic.very tfoot th,
.ui.table.basic.very tr td {
  border-top: none;
}
.ui.sortable.table thead th {
  border-left: none;
  color: #999daa;
}
.ui.sortable.table thead th.sorted {
  background: none;
  color: #999daa;
}

.ui.menu {
  font-family: inherit;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.ui.pagination.menu {
  box-shadow: none;
}
.ui.vertical.menu {
  box-shadow: none;
  border: none;
  border-top: 1px solid #535662;
  border-radius: 0px;
}
.ui.vertical.menu.pointing {
  border-top: none;
}
.ui.menu .item:before {
  background: none;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  border-radius: 0;
  border: 1px solid #bcbdc1;
}
.ui.checkbox input.hidden + label {
  font-weight: normal;
}
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ label:before {
  border-color: #00a98c;
}
.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after,
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type='radio']):indeterminate:focus ~ label:after {
  color: #00a98c;
}
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: #00a98c;
}
.ui.checkbox .box:after,
.ui.checkbox label:after {
  top: 1px;
  left: 1px;
}
.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  top: 2px;
  left: 1px;
}
.ui.input > input {
  font-family: inherit;
  border-radius: 0;
  border: 1px solid #bcbdc1;
}
.ui.form textarea,
.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'] {
  padding: 0.5em 0.7em;
  font-family: inherit;
  border-radius: 0;
  font-size: 1rem;
  border: 1px solid #bcbdc1;
  background-color: white;
  line-height: 1.25rem;
  box-sizing: border-box;
  color: #535662;
}
.ui.input > input:focus,
.ui.form textarea:focus,
.ui.form input:not([type]):focus,
.ui.form input[type='date']:focus,
.ui.form input[type='datetime-local']:focus,
.ui.form input[type='email']:focus,
.ui.form input[type='file']:focus,
.ui.form input[type='number']:focus,
.ui.form input[type='password']:focus,
.ui.form input[type='search']:focus,
.ui.form input[type='tel']:focus,
.ui.form input[type='text']:focus,
.ui.form input[type='time']:focus,
.ui.form input[type='url']:focus {
  border-color: #00a98c;
  outline: none;
  box-shadow: none;
  border-radius: 0;
}
.ui.checkbox label,
.ui.checkbox + label,
.ui.form .field > label {
  color: inherit;
  font-size: 1em;
}
.ui.checkbox label:hover,
.ui.checkbox + label:hover {
  color: inherit;
}
.ui.form select {
  border-radius: 0;
  border: 1px solid #bcbdc1;
}

.ui.selection.dropdown {
  min-width: auto;
  border-radius: 0;
  border: 1px solid #bcbdc1;
}
.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  font-size: 1rem;
}
.ui.inline.dropdown {
  color: #00a98c;
}
.ui.dropdown .menu > .item {
  font-size: 1em;
}
.ui.selection.dropdown .menu > .item {
  padding: 0.78571429rem 0.9rem !important;
}

.ui.basic.label {
  color: inherit;
}
.ui.green.label {
  background-color: rgba(0, 169, 140, 0.8) !important;
}
.ui.green.ribbon.label {
  border-color: rgba(0, 169, 140, 1) !important;
}

.ui.popup {
  color: #535662;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) tr {
    border-top: 1px dashed #bcbdc1;
    box-shadow: none !important;
  }
  .ui.form .fields .field {
    margin: 0 0 1em;
  }
}

/* MULTI-SELECT OVERRIDES */
.multi-select .dropdown .dropdown-heading {
  border-radius: 0 !important;
  border-color: #bcbdc1 !important;
}
.multi-select .dropdown .dropdown-heading-value {
  color: #535662 !important;
}
.multi-select .dropdown .select-item .item-renderer {
  display: flex;
}
.multi-select .dropdown .select-item .item-renderer input {
  flex: 1;
}
.multi-select .dropdown .select-item .item-renderer span {
  flex: 8;
}
.multi-select .dropdown .select-item .item-renderer span:hover {
  cursor: pointer;
}
.select-panel input {
  margin-bottom: 0;
}
.select-panel label input {
  display: inherit;
}
.select-list label input {
  display: inherit;
}
.select-list label span {
  font-weight: normal;
}

/* COLORS */
.dark-text {
  color: #535662;
}
.teal-text {
  color: #00a98c;
}
.red-text {
  color: #f18770;
}
.maroon-text {
  color: #b53f3f;
}
.orange-text {
  color: #f94e20;
}
.light-orange-text {
  color: #f8b857;
}
.blue-text {
  color: #02b4c5;
}
.light-gray-text {
  color: #bcbdc1;
}
.gray-text {
  color: #999daa;
}
.green-text {
  color: #92b307;
}
.white-text {
  color: white;
}
.orange-bg {
  background-color: #f94e20;
}
.light-orange-bg {
  background-color: #fff3f0;
}
.blue-bkgd {
  background-color: #4e7681;
}
.lightblue-bg {
  background-color: #e8eef0;
}
.lightgray-bg {
  background-color: #f1f1f1;
}
.lightgreen-bg {
  background-color: #f1f9c6;
}
.white-bg {
  background-color: white;
}
.gray-bg {
  background-color: #9b9b9b;
}

.input-with-microcopy {
  margin-bottom: 1rem;
}

.input-with-microcopy input {
  margin-bottom: 0.2rem;
}

.input-with-microcopy p {
  display: inline-block;
  padding-left: 0.5rem;
}

.close-icon {
  background-image: url('./imgs/icon-close--gray.svg');
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
  display: block;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}

.close-icon:hover,
.close-icon:focus,
.close-icon:active {
  background-color: transparent;
}

/* LAYOUT/GRID */

.variable-width-column {
  width: 100%;
  max-width: calc(1200px + 6rem);
  margin: auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

@media (max-width: 515px) {
  .variable-width-column {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.fixed-width-column {
  max-width: 50rem;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .fixed-width-column {
    max-width: calc(100% - 8rem);
  }
}

@media (max-width: 515px) {
  .fixed-width-column {
    max-width: calc(100% - 2rem);
  }
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.flex-wrapper .flex-item-1 {
  flex: 1;
}
.flex-wrapper .flex-item-2 {
  flex: 2;
}
.flex-wrapper .flex-item-3 {
  flex: 3;
}

.flex-wrapper .flex-input-container {
  flex-basis: 66%;
  align-self: flex-end;
}

.flex-wrapper .flex-input-container label,
.flex-wrapper .flex-input-container input {
  width: calc(100% - 3rem);
}

.flex-wrapper .flex-input-container input {
  width: calc(100% - 2rem);
  margin-bottom: 0;
  display: inline-block;
}

.flex-wrapper .flex-button {
  flex-basis: 33%;
  align-self: flex-end;
  height: 44px;
  padding: 0 1rem;
}

@media (max-width: 450px) {
  .hide-mobile {
    display: none;
  }
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz-out:hover, .hvr-buzz-out:focus, .hvr-buzz-out:active {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}