.subnav-container-order {
  background: white;
  margin-bottom: 2rem;
  padding: 2rem 0 1rem;
}
.subnav-container-order h2 {
  margin-bottom: 1.5rem;
}
.subnav-container-order .requisition-item-container {
  max-width: 55rem;
  padding-bottom: 0.5rem;
}
.subnav-container-order .requisition-item-table {
  width: 100%;
}
.subnav-container-order .requisition-item-table th {
  vertical-align: top;
  margin-right: 1rem;
  text-align: left;
}

.subnav-container-order .ordered-by-avatar {
  display: inline-block;
  vertical-align: top;
}
.subnav-container-order .ordered-by-name {
  display: inline-block;
  vertical-align: top;
  padding-left: 0.5rem;
}
.subnav-container-order .order-total-spend {
  column-width: 7.5rem;
}

@media (max-width: 768px) {
  .subnav-container-order h2 {
    margin: 0 1rem 1.5rem 1rem;
  }
  .subnav-container-order .requisition-item-table th {
    width: 40%;
    width: calc(50% - 1rem);
    margin: 0 0 0 1rem;
    display: inline-block;
  }
}

@media print {
  .subnav-container-order .print-pdf {
    display: block;
  }
}
