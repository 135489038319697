.order-capsule.purchase-order-item {
  position: relative;
  height: 8rem;
}

.order-capsule.purchase-order-item .purchase-order-item-buttons.flex-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}
