.ui.form select {
  padding: 0.5em 0.7em;
}

/*** OVERRIDES ***/
/* SEMANTIC UI */
.cc-form.ui.segment.basic {
  padding: 0;
}

.cc-form .ui.checkbox label {
  font-size: 0.9rem;
}
/* RECURLY */
.cc-form .recurly-hosted-field {
  height: 38px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: 1px solid #bcbdc1;
}

/*** END OVERRIDES ***/

.cc-form__row {
  display: flex;
  margin: 0 0 1rem;
}

.cc-form__field {
  width: 100%;
  margin-left: 0.75rem;
}

.cc-form__field:first-child {
  margin-left: 0;
}

.cc-form__field label {
  padding-left: 0;
  font-weight: 700;
}

.cc-form__field input[type='text'],
.cc-form__field .ui.input {
  width: 100%;
}

.cc-form__field .ui.input {
  margin-bottom: 1rem;
}

.cc-form button {
  padding: 0.5rem 4rem;
}

.cc-form__error-message {
  color: darkred;
  margin-left: 0.4rem;
}

.cc-form__error-message--top {
  margin: 1rem 0;
  margin-left: 0;
}

/* Stripe */

.stripe-card {
  border: gray solid 1px;
  padding: 10px;
  margin-bottom: 20px;
}

.stripe-logo {
  height: 30px;
  margin-top: 5px;
}