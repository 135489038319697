.modal-confirmation-delete-subheader {
  margin: 1rem 0;
  color: white;
}

.modal-confirmation-delete-task h1.site-h2 {
  color: white;
}

.modal-confirmation-delete .wrapper {
  margin: 15rem auto;
  max-width: 25rem;
}

button.cancel-delete-task.tertiary {
  text-decoration: none;
  color: white;
  font-weight: normal;
}

button.cancel-delete-task.tertiary:hover,
button.cancel-delete-task.tertiary:active {
  background-color: transparent;
  box-shadow: none;
}

button.confirm-delete-task {
  background-color: #f18770;
  border: none;
  border-radius: 0;
}
