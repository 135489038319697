.requisition-item {
  margin: 0 auto 2rem;
}

.requisition-item .ui.table.summary {
  background-color: rgb(249, 250, 251);
}

a.order-button,
a.order-button:hover {
  font-size: large;
}

.requisition-item-note {
  text-decoration: none;
  color: #bcbdc1;
  font-style: italic;
  font-size: 0.875rem;
}
.requisition-item .ui.top.attached.segment {
  padding-bottom: 0;
}
.requisition-item .ui.bottom.attached.segment {
  padding-top: 0.5em;
}
.requisition-item .ui.bottom.attached.segment .ui.basic.table {
  border-top: 1px dashed #bcbdc1;
  border-radius: 0;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable).order-summary-items tr {
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-bottom: none !important;
  }

  .ui.table:not(.unstackable).order-summary-items td {
    padding-left: 0 !important;
  }
}
