.modal.modal-product-search {
  box-shadow: 0 0.5rem 1rem rgba(85, 92, 110, 0.58);
  margin-top: 5rem;
  padding: 3rem 1rem 0;
  max-width: 600px;
  position: relative;
  max-height: calc(100vh - 6rem);
  min-height: calc(100vh - 20rem);
}
button.modal-close.close-icon {
  text-indent: -10000px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.modal-product-search .product-search-results-container {
  max-height: calc(100vh - 17rem);
  overflow-y: scroll;
}
.modal-product-search
  .product-search-results-container
  .officeluv-category-catalog {
  padding-bottom: 5rem;
}
.modal-product-search
  .product-search-results-container
  .officeluv-category-catalog
  .flex-wrapper {
  justify-content: space-around;
}
.modal-product-search
  .product-search-results-container
  .officeluv-category-catalog
  .flex-wrapper
  .product-item {
  border: 1px solid #eee;
}
.modal-product-search
  .product-search-results-container
  .subnav-category-request {
  text-align: right;
}
.modal-product-search-container .segment.placeholder .ui.header,
.modal-product-search-container .segment.placeholder .ui.header .sub.header {
  color: #bbb;
}
.modal-product-search-container .segment.placeholder .ui.header .icon.grey {
  color: #bbb !important;
}
.modal-product-search-trigger-wrap {
  text-align: right;
}
.modal-product-search-wrap .ui.button {
  margin: 1rem 0;
}

@media all and (max-width: 400px) {
  .modal.modal-product-search {
    margin-top: 2rem;
    max-height: calc(100vh - 4rem);
    min-height: calc(100vh - 4rem);
  }
}
