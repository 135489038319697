.request-item-form {
  width: 26rem;
}

.request-item-form-inner {
}

.request-item-form-submitted {
  background-color: #00a98c;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.request-item-form-inner label {
  width: 16rem;
  display: inline-block;
}

.request-item-form-inner .ui.button {
  width: 10rem;
  margin-top: 1rem;
}
.child-categories-box .request-item-form-inner .ui.button {
  margin-top: 0;
}

.request-item-form-inner label input {
  margin-bottom: 0;
  padding-top: 6px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
