.big-box-wrap {
  display: flex;
  align-items: center;
  color: #00a98c;
}

.big-box-wrap.alt,
.product-shipping.alt {
  color: #1aa7d2;
}

.big-box-icon,
.big-box-icon.alt {
  display: inline-block;
  height: 23px;
  margin-left: 0.4rem;
}

.modal-product .big-box-icon {
  display: inline-block;
  height: 2rem;
  width: 2.1rem;
  margin-right: 0.6rem;
  margin-left: 0;
}
