.task-item {
  max-width: 35rem;
  border: 1px solid gray;
  padding: 0.5rem;
}

.task-item-title {
  width: 100%;
  display: block;
}

.task-item-completed {
  width: 100%;
  display: block;
  text-align: right;
  color: #bcbdc1;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 0.7rem;
}

.task-item-completed img {
  margin-right: 0.25rem;
}

.task-item-completed.is-completed {
  color: #92b307;
}

.one-off-task-item,
.recurring-task-item {
  cursor: pointer;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-left: 0rem;
  margin-right: 1rem;
  margin-bottom: 0.75rem;
  border: 1px solid #d8dade;
  border-radius: 0;
  width: 9rem;
  height: 9rem;
  position: relative;
}

.one-off-task-item {
  box-shadow: 0 1px 1px #8f949f;
}

.recurring-task-items:hover .recurring-task-item:hover,
.one-off-task-items:hover .one-off-task-item:hover {
  box-shadow: 0 0.06rem 0.125rem #8f949f;
  transition: all 0.1s ease-in;
}

.recurring-task-item:active,
.one-off-task-item:active {
  box-shadow: 0 0 0.125rem #8f949f;
  transition: all 0.1s ease-in;
}

.one-off-task-item:hover .title {
  color: #292c35;
}
