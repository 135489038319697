tr.order-row td .product-item-image {
  width: 42px;
  margin: auto;
}

.product-name {
  font-weight: bold;
  cursor: pointer;
}

.product-unit-stock {
  padding-left: 0.2rem;
  font-weight: normal;
}

.product-tally {
  color: #717171;
}
.show-mobile {
  display: none;
}

.order-row .custom-instructions {
  margin-bottom: 0.5rem;
}

@media all and (max-width: 767px) {
  .ui.table:not(.unstackable) tr > td.show-mobile {
    display: block;
  }
  .ui.table:not(.unstackable) tr > td.hide-mobile {
    display: none !important;
  }
}
