.recurring-requisitions {
  margin-top: 2rem;
  margin-bottom: 50vh;
  width: 100%;
}

.recurring-requisitions .recurring-requisitions-container {
  width: 40%;
}

.recurring-requisitions .scheduled-requisitions-container {
  width: 50%;
}

.recurring-requisitions .recurring-requisitions-header-container {
  margin-bottom: 2rem;
}

.recurring-requisitions
  .recurring-requisitions-container
  .ui.relaxed.list:not(.horizontal)
  > .item:not(:last-child) {
  padding-bottom: 1.5em;
}
.recurring-requisitions
  .recurring-requisitions-container
  .ui.relaxed.list
  .item
  p {
  margin-bottom: 0.5em;
}

@media (max-width: 480px) {
  .recurring-requisitions {
    margin-bottom: 5vh;
  }

  .recurring-requisitions .recurring-requisitions-header-container {
    margin-bottom: 6rem;
  }
  .recurring-requisitions
    .recurring-requisitions-header-container
    .recurring-requisitions-header {
    margin-bottom: 0;
  }

  .recurring-requisitions .create-recurring-requisition {
    float: left;
    margin: 1rem 0;
  }

  .recurring-requisitions .recurring-requisitions-container,
  .recurring-requisitions .scheduled-requisitions-container {
    margin-top: 1.5rem;
    width: 100%;
  }
}
