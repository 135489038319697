.expandable-text {
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  min-height: 2rem;
}
.expandable-text.expanded {
  padding-bottom: 2rem;
}

.expandable-text__fade {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  background-color: transparent;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}
.expandable-text.expanded .expandable-text__fade {
  height: 2rem;
}

button.tertiary.expandable-text__more {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: white;
}
