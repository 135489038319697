table.invoices .invoice-state.past_due {
  color: red;
}
table.invoices .invoice-state.open {
}
table.invoices .invoice-state.failed {
  color: red;
}
table.invoices .invoice-state.collected {
}

@media (max-width: 450px) {
  table.invoices .due-date,
  table.invoices .save-invoice {
    display: none;
  }
}
