.cart-product {
  padding: 0 1rem;
}

.cart-product.no-padding {
  padding: 0 0.5rem;
}

.cart-product.no-padding:last-child {
  margin-bottom: 1rem;
}

.cart-product-requisition {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;
  background-color: white;
}

.cart-product.no-padding .cart-product-requisition {
  margin: 0;
  padding: 1rem 0;
}

.cart-product-requisition .item-image {
  margin-right: 0.5rem;
}
.cart-product-requisition .item-image:hover {
  cursor: pointer;
}
.cart-product-requisition .item-image .product-item-image img {
  width: 3rem;
  height: auto;
}

.cart-product-requisition .item-details {
  padding: 0 0.5rem;
  flex: 1;
}

.cart-product-requisition .item-actions {
  position: relative;
  width: 4rem;
}

.cart-product-requisition
  .item-details
  button.cart-item-button-details.tertiary {
  box-shadow: none;
  background: none;
  padding: 0;
  text-align: left;
  color: #555c6e;
  line-height: 1.1rem;
}
