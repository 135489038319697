.variable-width-column.promoted-brands {
  margin-bottom: 20vh;
}

.promoted-brands__header {
  margin: 2rem 0;
}

.promoted-brands__banner {
  /*placeholder*/
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  /*end placeholder*/
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  height: 200px;
  background: white;
}

.promoted-brands__row {
  margin: 0.5rem 0 2rem;
}

.promoted-brands__row-header h4 {
  display: inline-block;
  margin-right: 1rem;
}

.promoted-brands__row-content {
  display: flex;
  justify-content: space-between;
}

.promoted-brands__row-left {
  max-width: calc(100% - 360px); /* width of right item + margin */
}

.promoted-brands__row-right {
  border-top: 1px solid #535662;
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  margin-left: 40px;
  padding: 0.6rem;
  box-sizing: border-box;
  height: 366px;
  overflow: hidden;
  background-color: white;
  position: relative;
}

.promoted-brands__image {
  width: 100%;
  max-width: 300px;
  margin: auto;
  line-height: 1;
}

.promoted-brands__description {
  overflow: hidden;
  padding-top: 0.6rem;
}

.promoted-brands__fade {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 145px;
  background-color: transparent;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

button.tertiary.promoted-brands__more {
  position: absolute;
  bottom: 13px;
  right: 10px;
  background-color: white;
}

@media (max-width: 980px) {
  .promoted-brands__row-content {
    flex-direction: column-reverse;
  }

  .promoted-brands__row-left {
    max-width: 100%;
  }

  .promoted-brands__row-right {
    width: 100%;
    max-width: 350px;
    min-width: 0;
    margin: 0 auto;
  }
}
