.well-container .navbar-attendant {
  overflow: visible;
}

.navbar-attendant {
  display: flex;
  /* background-color: #f8f8f7; */
  position: relative;
  border-bottom: 0.5rem solid white;
}

.navbar-attendant .nav-wrapper {
  display: block;
  width: 100%;
}

.navbar-attendant .nav-wrapper .nav-upper.signin {
  display: block;
  text-align: right;
  background-color: #3f4351;
}

.navbar-attendant .nav-wrapper .nav-upper {
  display: flex;
  justify-content: space-between;
  background-color: #535661;
}

.navbar-attendant .nav-wrapper .nav-lower {
  display: flex;
  justify-content: space-between; /* for attendant page */
}

.navbar-attendant .flex-item {
  align-items: flex-start;
  margin-right: 1em;
}
.nav-online-offline {
  vertical-align: text-top;
  color: #f18770;
  display: inline-block;
  line-height: 1rem;
  border: 1px solid #f18770;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.navbar-attendant a {
  color: white;
  display: inline-block;
  font-size: 0.875rem;
  vertical-align: middle;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
}

.navbar-attendant a.fitted {
  padding: 0;
}

.navbar-attendant a.nav-user-link:hover,
.navbar-attendant a.nav-company-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease;
}

.navbar-attendant a.nav-user-link.employee-avatar:hover {
  background-color: initial;
  transition: none;
}

.navbar-attendant .home-link {
  padding-right: 3rem;
}

.navbar-attendant .logo.desktop.signin {
  background-color: #3f4351;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  margin-top: 0;
  border-radius: 0;
}

.navbar-attendant .logo.mobile {
  display: none;
}

.navbar-attendant a.logo.desktop {
  padding: 1rem 1.5rem;
  /* background: #f8f8f7; */
  border-radius: 0;
}

.navbar-attendant a.logo:hover {
  background-color: none;
}

.button-cart {
  float: right;
}

.button-cart.button-with-icon {
  padding: 0 1rem;
  /* background-color: #f8f8f7; */
  background-color: white;
  color: #00a98c;
  margin: 0.5rem 0;
  height: 3.5rem;
  border-left: solid 1px #ccc;
  border-radius: 0;
  text-align: left;
}

.button-cart.button-with-icon .cart-quantity {
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  padding-right: 3.5rem;
  margin-bottom: -0.25rem;
}

.button-cart.button-with-icon .cart-label {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 300;
  min-width: 5rem;
}
.button-cart.button-with-icon .cart-label .cart-icon {
  vertical-align: text-bottom;
  display: inline-block;
  margin-right: 0.1rem;
}

button.close-cart-button {
  margin-top: 0;
  position: absolute;
  z-index: 4;
  top: 66px;
  right: 30px;
}

.cart-container {
  position: relative;
}

.cart-container .cart {
  margin-top: -0.25rem;
  position: absolute;
  z-index: 3;
  right: 1rem;
  top: 3rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.nav-daily-planner {
  display: flex;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.nav-daily-planner .daily-palnner-for {
  font-size: 1.5rem;
  color: #535661;
  font-weight: 300;
  padding-top: 1.75rem;
  text-transform: uppercase;
}

.nav-daily-planner .select {
  padding-left: 0;
}

.nav-daily-planner .select select {
  width: 9.5rem;
  height: 2.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #00a98c;
  padding-top: 0.1rem;
  text-transform: uppercase;
  padding-bottom: 0.1rem;
  border: solid 2px #00a98c;
  border-radius: 0;
  background-image: url('./../../imgs/down-arrow-icon--gray.svg');
}

.nav-logo {
  display: flex;
  align-items: center;
}

.navbar-attendant.navbar-sign-in {
  border: none;
  background-color: #3f4351;
}

@media (max-width: 725px) {
  .nav-logo {
    display: none;
  }
}

@media (max-width: 450px) {
  .navbar-attendant {
    display: block;
    color: white;
  }

  .navbar-attendant a {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }

  .nav-logo {
    display: block;
  }

  .navbar-attendant .flex-wrapper {
    margin-bottom: 2rem;
  }

  .navbar-attendant .logo.mobile {
    display: inline-block;
    top: 0.5rem;
    left: 1rem;
    width: 2rem;
    padding: 0;
  }

  .navbar-attendant .logo.desktop {
    display: none;
  }

  .navbar-attendant .nav-wrapper .nav-upper {
    /* background: #f8f8f7; */
    height: 45px;
  }

  .navbar-attendant .nav-wrapper .nav-lower {
    display: block;
  }

  .nav-daily-planner {
    padding-left: 1rem;
  }

  .button-cart.button-with-icon {
    border-radius: 0;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    padding: 0 0.5rem 0 0;
    text-align: right;
    margin: 0.4rem 0;
  }

  .button-cart.button-with-icon .cart-quantity,
  .button-cart.button-with-icon .cart-label {
    display: inline-block;
    min-width: auto;
    padding: 0;
    vertical-align: middle;
    font-size: 1.4rem;
  }
  .button-cart.button-with-icon .cart-label span {
    display: none;
  }
}

@media (max-width: 768px) {
  .cart-container {
    position: fixed;
    width: 100%;
    z-index: 5;
    top: 0;
    right: 0;
  }

  .cart-container .cart {
    width: 100%;
    top: 0;
    right: 0;
    max-height: 100vh;
  }

  button.close-cart-button {
    margin-top: 0.2rem;
    top: 1rem;
    right: 1rem;
  }
}
