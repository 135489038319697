.product-item-list-row-wrap {
  position: relative;
  margin-left: -1rem;
}

.product-item-list-row-wrap .list-row-button {
  position: absolute;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 1.315rem;
  background-color: transparent;
  box-shadow: none;
  background-image: linear-gradient(
    90deg,
    rgba(247, 247, 246, 0),
    rgba(247, 247, 246, 1) 27%
  );
  transition: opacity 0.3s;
  z-index: 2;
  padding: 0 1rem 0 1.5rem;
}

.product-item-list-row-wrap .list-row-button.before {
  opacity: 0;
  padding: 0 1.5rem 0 1rem;
  height: 350px;
  margin-top: 0.5rem;
  background-image: linear-gradient(
    90deg,
    rgba(247, 247, 246, 1) 73%,
    rgba(247, 247, 246, 0)
  );
}
.product-item-list-row-wrap .list-row-button.after {
  right: -1.5rem;
  height: 350px;
  margin-top: 0.5rem;
}
.product-item-list-row-wrap .list-row-button.before span,
.product-item-list-row-wrap .list-row-button.after span {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  text-align: center;
  border: 1px solid #999999;
  color: gray;
  padding: 0 0 0 0.8rem;
  border-radius: 0.25rem;
  font-weight: 700;
}
.product-item-list-row-wrap .list-row-button.before span {
  padding: 0 2rem 0 0;
}

.product-item-list-row-wrap .list-row-button:hover {
  background-color: white;
  background-image: none;
}

.product-item-list-row .row-view-all-button {
  color: #00a98c;
  background-color: white;
  box-shadow: none;
  width: 9rem;
  height: 100%;
}

.product-item-list-row .row-view-all-button:hover {
  box-shadow: none;
}

.product-item-list-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 450px) {
  .product-item-list-row {
    flex-wrap: wrap;
  }
  .product-item-list-row-wrap .list-row-button {
    display: none;
  }
  .product-item-list-row .view-all-item {
    height: auto;
  }
  .product-item-list-row .view-all-item .row-view-all-button {
    width: 100%;
    border: 1px solid;
  }
}
