.supply-levels-container.flex-wrapper {
  margin: 3rem auto;
  max-width: 55rem;
}
.supply-levels-container .supply-levels-budget-codes-edit {
  flex: 3;
  margin-right: 2rem;
  margin-bottom: 5rem;
}
.supply-levels-container .supply-levels-budget-codes-sidebar {
  flex: 1;
}
.supplies-order-budget-codes-edit .section-header {
  margin-bottom: 1rem;
}
.supply-levels-budget-codes-sidebar .budget-link {
  margin: 1rem 0;
  width: 100%;
  height: 7rem;
}
.supply-levels-budget-codes-sidebar .budget-code-links {
  margin-top: 3rem;
}
