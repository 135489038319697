.supply-item-in-stock p.item-in-stock-subheader {
  padding-bottom: 0.25rem;
  padding-top: 1rem;
  font-size: 1rem;
  color: darkslategray;
  font-weight: 700;
  text-align: center;
}

.supply-item-in-stock .item-in-stock-details {
  padding: 0.5rem 0;
  text-align: center;
}
.supply-item-in-stock .item-in-stock-details .minimum-in-stock-header {
  font-size: 1rem;
}

.supply-item-in-stock .in-stock-icon {
  padding-top: 0.5rem;
  display: none;
}

.supply-item-in-stock .mobile-details {
  display: none;
}

.item-in-stock-form.flex-wrapper .in-stock-quantity-input {
  margin: auto;
}

.supply-item .in-stock-quantity-input .input-quantity-readonly,
.supply-item .in-stock-quantity-input .input-quantity {
  width: 4rem;
  border: none;
  font-size: 3rem;
  color: darkslategray;
  padding: 0;
  font-weight: 200;
}
.supply-item .in-stock-quantity-input .input-quantity-readonly {
  line-height: 4rem;
}
.supply-item .in-stock-quantity-input .input-quantity-readonly-header {
  display: none;
}

.supply-item .in-stock-quantity-input .input-quantity-increment,
.supply-item .in-stock-quantity-input .input-quantity-decrement {
  margin-top: 0.25rem;
  color: #bcbdc1;
  border: 1px solid;
  border-radius: 50%;
  font-weight: 300;
  height: 1.5rem;
  width: 1.5rem;
}
.supply-item .in-stock-quantity-input .input-quantity-increment {
  padding: 0 0 0 0.1rem;
}
.supply-item .in-stock-quantity-input .input-quantity-decrement {
  padding: 0 0.1rem;
}

.supply-item .in-stock-quantity-input .input-quantity-increment:hover,
.supply-item .in-stock-quantity-input .input-quantity-decrement:hover {
  background-color: transparent;
}

@media (min-width: 1221px) and (max-width: 1370px) {
  .item-in-stock-form.flex-wrapper
    .in-stock-quantity-input
    .input-quantity-increment,
  .item-in-stock-form.flex-wrapper
    .in-stock-quantity-input
    .input-quantity-decrement {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .item-in-stock-form.flex-wrapper
    .in-stock-quantity-input
    .input-quantity-increment,
  .item-in-stock-form.flex-wrapper
    .in-stock-quantity-input
    .input-quantity-decrement {
    padding: 0;
  }
}

@media (max-width: 450px) {
  .supply-item-in-stock .mobile-details {
    display: block;
    text-align: center;
  }

  .supply-item-in-stock .desktop-details {
    display: none;
  }

  .supply-item-in-stock {
    padding: 0.5rem 0 1rem 0;
  }
}

@media (max-width: 400px) {
  .item-in-stock-form.flex-wrapper
    .in-stock-quantity-input
    .input-quantity-increment,
  .item-in-stock-form.flex-wrapper
    .in-stock-quantity-input
    .input-quantity-decrement {
    padding: 0;
  }
}
