.root-orphan {
  text-align: center;
  background: white;
  margin: 10vh auto;
  max-width: 35rem;
  padding: 1rem 1rem 3rem;
  box-shadow: 0 1px 3px #8f949f;
  border-radius: 0.25rem;
}
.root-orphan p {
  margin: 2rem 4rem 3rem;
}
