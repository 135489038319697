.requisition-shipping-title {
  margin-bottom: 1rem;
}

.requisition-delivery-info {
  margin: 0 0 2rem;
}

.requisition-shipping,
.requisition-billing {
  display: block;
  vertical-align: top;
}
.requisition-billing a {
  text-decoration: none;
}

.requisition-shipping {
  margin-bottom: 2rem;
}

.billing-purchaser-avatar {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.billing-purchaser-avatar > span {
  margin-left: 0.5rem;
}
