/* Custom Scrollbar */
.officeluv-sibling-category-list .ui.dropdown .menu::-webkit-scrollbar {
  display: block;
  width: 7px;
  background-color: #efefef;
}

.officeluv-sibling-category-list .ui.dropdown .menu::-webkit-scrollbar-thumb {
  background: #aaa;
}
/* END Custom Scrollbar */

/* Overrides to sub-category ui dropdowns */
.officeluv-sibling-category-list .ui.pointing.dropdown > .menu {
  box-shadow: -4px 4px 4px 0 rgba(34, 36, 38, 0.15);
}

.officeluv-sibling-category-list .ui.menu .ui.dropdown .menu .item.no-hover {
  padding-bottom: 0;
}

.officeluv-sibling-category-list
  .ui.menu
  .ui.dropdown
  .menu
  .item.no-hover:hover {
  background: transparent !important;
}

.officeluv-sibling-category-list .ui.accordion .item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.officeluv-sibling-category-list .ui.dropdown .menu > .item .dropdown.icon {
  float: initial;
}
/* END Overrides to sub-category ui dropdowns */

/* Overrides to top-level ui menus */
.officeluv-sibling-category-list .ui.menu > .item {
  padding-left: 1em;
  padding-right: 1em;
}

.officeluv-sibling-category-list .ui.menu > .item,
.officeluv-sibling-category-list .ui.menu > .item > .ui.dropdown {
  cursor: pointer;
  color: #535662;
  font-weight: bold;
}

.officeluv-sibling-category-list .ui.menu > .item:hover,
.officeluv-sibling-category-list .ui.menu > .item.active-link:hover {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.95);
}

.officeluv-sibling-category-list .ui.menu > .item:hover .ui.dropdown,
.officeluv-sibling-category-list
  .ui.menu
  > .item.active-link:hover
  .ui.dropdown {
  color: rgba(0, 0, 0, 0.95);
}
/* END Overrides to top-level ui menus */

/* Override Menu Accordions */
.officeluv-sibling-category-list .ui.menu .ui.accordion .title {
  font-family: inherit;
  padding: 0;
}
/* END Override Menu Accordions */

.officeluv-sibling-category-list {
  display: flex;
  background-color: #ffffff;
  background-size: cover;
  background-position-x: center;
  background-position-y: -3rem;
  background-repeat: no-repeat;
  position: relative;
  background-attachment: fixed;
}

.officeluv-sibling-category-list .sibling-category {
  display: inline-block;
}

.officeluv-sibling-category-list a.sibling-category-link {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  color: #cccccc;
  border-radius: 0.25rem;
  margin-right: 1.5rem;
  text-decoration: none;
}

.officeluv-sibling-category-list a.sibling-category-link .link-content {
  display: inline-block;
  white-space: initial;
}

.officeluv-sibling-category-list a.sibling-category-link .bold-text {
  border-bottom: 1px solid rgba(255, 255, 255, 0.65);
}

.officeluv-sibling-category-list a.sibling-category-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in;
}

.officeluv-sibling-category-list a.sibling-category-link:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

/* ACTIVE STYLING */

.officeluv-sibling-category-list .ui.menu > .item.active-link,
.officeluv-sibling-category-list a.active-link {
  transition: all 0.1s ease-in;
  background-color: rgba(255, 255, 255, 0.15);
  color: gray;
  border-bottom: solid 4px #00a98c;
  border-radius: 0;
}

.officeluv-sibling-category-list .ui.menu > .item.active-link > .ui.dropdown {
  color: grey;
}

@media (max-width: 1440px) {
  .officeluv-sibling-category-list {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .category-link-container {
    white-space: nowrap;
    width: 100%;
    overflow: visible;
  }
}

@media (max-width: 768px) {
  .officeluv-sibling-category-list .ui.menu .item > .ui.dropdown {
    width: 100%;
  }

  .officeluv-sibling-category-list .ui.menu .ui.accordion .title {
    padding-bottom: 1em;
  }
}
