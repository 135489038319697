.cleaning-comment.commenter-avatar {
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 1.25rem;
}

.cleaning-comment.commenter-avatar.employee-avatar {
  margin-right: 0.25rem;
  align-self: flex-start;
}

.cleaning-comment-container.employee .cleaning-comment.body {
  border: 1px solid #a45faa;
  /* background-color: #F4EFF6; */
  border-radius: 0.25rem;
  padding: 0.5rem;
  padding-top: 0;
  border: none;
  color: #555c6e;
}

.cleaning-comment.commenter-name {
  font-size: 0.7rem;
  font-weight: 600;
  margin-top: 0;
}

.cleaning-comment.date {
  text-align: right;
  padding-right: 0.75rem;
  color: #bcbdc1;
  font-size: 0.7rem;
  margin-top: 0;
}

.cleaning-comment.name-date {
  display: flex;
}

.cleaning-comment-container.employee {
  display: flex;
  margin-top: 1.5rem;
}

.cleaning-comment.comment-details-container {
  align-self: flex-start;
}
