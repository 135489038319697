.cleaning-details {
  margin: 0 auto;
}

.cleaning-details-container.flex-wrapper {
  justify-content: space-between;
}

.cleaning-details .shift-notes.flex-item {
  flex-basis: 35%;
  min-width: 200px;
  align-self: flex-start;
  background-color: white;
  border-top: 1px solid #535662;
}

.cleaning-details-tasks-container.flex-item {
  flex-basis: 60%;
  min-width: 310px;
  align-self: flex-start;
}

@media (max-width: 1100px) {
  .shift-notes.flex-item {
    margin-right: 3rem;
  }
}

@media (max-width: 1050px) {
  .cleaning-details .shift-notes.flex-item,
  .cleaning-details-tasks-container.flex-item {
    flex-basis: 100%;
    margin-right: 0;
  }
}

.cleaning-header-container .cleaning-subheader {
  margin-bottom: 0.5rem;
}

.cleaning-details-cleaning-staff.flex-wrapper {
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  padding-top: 2rem;
}

.cleaning-details-cleaning-staff .staff-details .flex-item {
  margin-right: 2rem;
}

.cleaning-details-cleaning-staff .staff-avatar-clocked-in {
  right: 0;
  top: -2.5rem;
}

.cleaning-details-cleaning-staff .staff-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  color: #535661;
  font-weight: 600;
}

.cleaning-details-cleaning-staff .staff-times.light-gray-text {
  color: gray;
  font-weight: 500;
}

.cleaning-details-cleaning-staff .staff-avatar .avatar img,
.cleaning-details-cleaning-staff .staff-avatar .avatar svg {
  border: none;
}

.cleaning-details-cleaning-staff .staff-name-clocked-in {
  display: block;
  vertical-align: middle;
}
