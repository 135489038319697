.contact-button {
    position: fixed;
    bottom: 17px;
    right: 122px;
    z-index: 9999999;
    background-color: #00a98c;
    color: #fff;
    padding: 3px 30px;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: opacity, top, bottom;
}