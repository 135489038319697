input.with-icon {
  background-repeat: no-repeat;
  background-position: 0.75rem 1rem;
  padding-left: 2rem;
}

label span.required {
  color: #00a98c;
  font-size: smaller;
}
