.calendar-agenda {
}

.calendar-agenda__header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

button.ui.button.calendar-agenda__icon-button {
  background-color: transparent;
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

button.ui.button.calendar-agenda__icon-button:hover {
  background-color: transparent;
}
