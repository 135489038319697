.navbar-stipend {
  margin: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.navbar-stipend p {
  font-weight: 700;
  color: #999daa;
}

.navbar-stipend h3.ui.header.navbar-stipend__balance {
  font-weight: lighter;
  margin: 0;
  color: #999daa;
}

.navbar-stipend h3.ui.header.navbar-stipend__balance--positive {
  color: #00a98c;
}
