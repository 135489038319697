.loader {
  margin: 2rem auto;
  height: 60px;
  text-align: center;
  padding: 1em;
  display: block;
  vertical-align: top;
}
.loader svg {
  enable-background: new 0 0 50 50;
  width: 36px;
  height: 45px;
}

/*
  Set the color of the icon
*/
.loader svg path,
.loader svg rect {
  fill: gray;
}
