.subnav-container-shipments-empty {
  margin-bottom: 2rem;
}

.subnav-container-shipments {
  padding: 1rem 0;
  margin-bottom: 3rem;
  position: relative;
}
.subnav-container-shipments h2 {
  margin-bottom: 1.5rem;
  padding-left: 1rem;
}
