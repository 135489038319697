.role-detail {
  background: 'white';
}

.role-detail.compact {
  padding: 0.5rem 0;
}

.role-detail.compact + .role-detail.compact {
  margin-top: 0;
}

.ui.segment.attached.top.role-detail.compact {
  border-top-color: #b7b7b7;
}
