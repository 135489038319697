.modal-create-budget-code .budget-code-form-new-toggle {
  margin-top: 2rem;
}
.modal-create-budget-code .budget-code-form-new-toggle .success {
  color: white;
  background-color: #00a98c;
  padding: 1rem;
}

@media (max-width: 450px) {
  .modal-create-budget-code .budget-code-form-new-toggle {
    max-width: 100%;
  }
}
