.requisitions-header h2 {
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}
.requisitions-date-title {
  padding-left: 1rem;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}
.requisitions-list
  .requisitions-list-date:first-child
  .requisitions-date-title {
  margin-top: 0;
}
