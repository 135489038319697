.recurring-requisition-form h4.form-header {
  margin-left: 0.75rem;
  margin-bottom: 1rem;
}

.recurring-requisition-form .shipping-details {
  margin-top: 2rem;
}

.recurring-requisition-form select {
  margin-bottom: 1rem;
}

.recurring-requisition-form .form-checkbox-row {
  margin: 1rem 0;
}
.recurring-requisition-form .form-checkbox-row-desc {
  margin: 0.25rem 1.25rem 1.5rem;
  max-width: 25rem;
}

.recurring-requisition-form .form-checkbox-row label.input-checkbox {
  padding-left: 0;
}

.recurring-requisition-form .form-checkbox-row span.input-checkbox-label {
  padding-left: 0.25rem;
}
.recurring-requisition-form .form-radio-row {
  margin: 1rem 0;
}
.recurring-requisition-form .form-radio-row p {
  margin-bottom: 10px;
}
.recurring-requisition-form .form-radio-row input {
  float: left;
  margin-right: 0;
}
.recurring-requisition-form .form-textarea-row {
  width: 80%;
}

.schedule-form .SingleDatePickerInput__withBorder {
  border: none;
}

.recurring-requisition-form input#recurring-requisition-start-at {
  padding: 0.5rem;
  background-color: rgba(246, 251, 251, 0.82);
  width: 100%;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  border: 1px solid #d8dade;
  background-color: white;
  line-height: 1.25rem;
  box-sizing: border-box;
}

.recurring-requisition-form .recurring-req-details-input-4 span.start-at-label {
  display: block;
  margin-left: 0.75rem;
}

.recurring-requisition-form .recurring-req-details-input-1,
.recurring-requisition-form .recurring-req-shipping-input-1,
.recurring-requisition-form .recurring-req-shipping-input-2,
.recurring-requisition-form .recurring-req-shipping-input-3,
.recurring-requisition-form .recurring-req-shipping-input-4,
.recurring-requisition-form .recurring-req-shipping-input-5,
.recurring-requisition-form .recurring-req-shipping-input-6,
.recurring-requisition-form .recurring-req-shipping-input-7,
.recurring-requisition-form .recurring-req-shipping-input-8 {
  width: 80%;
}

.recurring-req-shipping-textarea {
  width: 80%;
  display: block;
}

.recurring-requisition-form .recurring-req-details-input-1,
.recurring-requisition-form .recurring-req-details-input-2,
.recurring-requisition-form .recurring-req-details-input-3 {
  height: 4.5rem;
}
.recurring-requisition-form.delete {
  width: 80%;
}
.recurring-requisition-form.delete::after {
  display: table;
  content: '';
  clear: both;
}

.recurring-requisition-form.delete button {
  float: right;
}

.recurring-requisition-form.delete button.danger {
  color: #d02323;
}

@media (max-width: 480px) {
  .recurring-requisition-form .recurring-req-details-input-1,
  .recurring-requisition-form .recurring-req-shipping-input-1,
  .recurring-requisition-form .recurring-req-shipping-input-2,
  .recurring-requisition-form .recurring-req-shipping-input-3,
  .recurring-requisition-form .recurring-req-shipping-input-4,
  .recurring-requisition-form .recurring-req-shipping-input-5,
  .recurring-requisition-form .recurring-req-shipping-input-6,
  .recurring-requisition-form .recurring-req-shipping-input-7,
  .recurring-requisition-form .recurring-req-shipping-input-8,
  .recurring-req-shipping-textarea {
    width: 100%;
  }
}
