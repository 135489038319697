.droppable {
  position: relative;
}

.droppable-phantom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.droppable-placeholder {
  display: none;
}

.droppable-placeholder.active {
  display: block;
  height: 6rem;
  width: 100%;
  background-color: #b1e2da;
}
