.subnav-container-category .catalog-heading-wrapper.flex-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.subnav-container-category .catalog-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.subnav-category-request {
  margin-top: 2rem;
}

.subnav-category-request-label {
  display: inline-block;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
}

.subnav-category-request-toggle {
  display: inline-block;
}

@media (max-width: 768px) {
  .subnav-container-category .catalog-heading-wrapper.flex-wrapper {
    justify-content: flex-start;
    flex-direction: column;
  }

  .subnav-category-request {
    width: 100%;
    margin-top: 2rem;
  }

  .subnav-category-request button,
  .subnav-category-request .subnav-category-request-label {
    display: block;
  }
}
