.schedule-attendant-form .SingleDatePicker__block {
  margin-bottom: 1rem;
}
.schedule-attendant-form .SingleDatePickerInput__withBorder {
  border: 1px solid #bcbdc1;
}
.schedule-attendant-form .SingleDatePickerInput_calendarIcon {
  padding: 0;
  vertical-align: text-bottom;
}
.schedule-attendant-form .DateInput.DateInput_1 input {
  padding: 8px 12px 8px;
  font-size: 0.875rem;
  border: none !important;
}
.schedule-attendant-form .DateInput__block {
  width: auto;
}
