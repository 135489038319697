form.cleaning-comment-form {
  width: 100%;
  background-color: white;
  padding: 1.5rem;
  padding-top: 1rem;
}

form.cleaning-comment-form label {
  padding-left: 0;
  font-style: italic;
  font-size: 0.7rem;
  color: #bcbdc1;
}

.cleaning-comment-form .button-wrap {
  text-align: right;
}
