.subnav-container-supply-levels.supply-levels {
  padding-top: 1rem;
  padding-bottom: 5rem;
  min-height: 100vh;
}
.subnav-container-supply-levels .supply-levels-wrap {
  max-width: 99.99%;
}

.empty-state-arrow {
  display: inline-block;
}

.officeluv-supplies-container .supply-levels-items.flex-item {
  width: 45%;
  margin-right: 2rem;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
}

.officeluv-supplies-container .supply-levels-items.flex-item.flex-wrapper {
  height: 100%;
}

.supply-levels-empty-state-container {
  width: 100%;
}

.supply-levels .edit-budget-codes-wrap {
  text-align: right;
  width: 100%;
  padding: 1rem 0 1rem;
}

.supply-levels .open-products,
.supply-levels .ui.basic.segment.open-products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  background: white;
}

.supply-levels .ui.basic.segment.open-products.favorites {
  background: transparent;
}

.supply-levels .ui.basic.loading.segment.open-products {
  height: 100vh;
  opacity: 0.5;
}

.supply-levels .open-products-group {
  width: 100%;
}

.supply-levels .open-products-group-name {
  padding: 1rem 0 0.5rem 1rem;
}

.supply-levels.variable-width-column {
  /* background-color: #f8f8f7; */
}

.supply-levels .ui.menu {
  box-shadow: none;
  border: none;
  border-top: 1px solid #535662;
  margin-bottom: 0;
}

.supply-levels button.ui.button.edit-mode {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: auto !important;
}

.supply-levels button.ui.button.edit-mode i.icon {
  margin: 0 !important;
}

.supply-levels button.ui.button.edit-mode.active {
  color: #fff;
  background-color: #00a98c;
}

@media (max-width: 768px) {
  .supply-levels-empty-state .empty-state-info.flex-wrapper .flex-item {
    max-width: none;
    margin-bottom: 1rem;
  }
  .supply-levels-empty-state
    .empty-state-info.flex-wrapper
    .flex-item.empty-state-arrow {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media (max-width: 450px) {
  .supply-levels.variable-width-column {
    padding: 0 !important;
  }

  .supply-levels-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 1rem;
  }

  .subnav-container-supply-levels.supply-levels {
    padding-top: 0;
  }

  .officeluv-supplies-container .supply-levels-items.flex-item {
    margin-right: 0;
  }

  .officeluv-supplies-container .supply-levels-items.flex-wrapper {
    height: 100%;
  }
}
