.officeluv-category-container {
  /* background-color: #f8f8f7; */
}

.request-container {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.officeluv-category-container .content-desktop,
.officeluv-category-container .child-list-and-content-desktop {
  padding-bottom: 5rem;
}

.officeluv-category-container.flex-wrapper {
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.officeluv-category-container .child-categories-box.flex-item {
  width: 12rem;
  margin-left: -7rem;
  margin-top: 3rem;
}

.officeluv-category-container .catalog-box.flex-item {
  width: 25rem;
  margin-right: 2rem;
  margin-top: 1rem;
  flex-grow: 1;
  flex-shrink: 1;
}

.officeluv-category-container .product-search-results {
  flex-grow: 1;
  padding-top: 0;
}

.officeluv-category-container .product-search-results .catalog-box.flex-item {
  margin: 0;
}

.officeluv-category-container .child-list-mobile {
  display: none;
}

.officeluv-category-container .variable-width-column {
  /*padding-left: 7.5rem;*/
}

.variable-width-column.category-link-container {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.officeluv-category-container .child-categories-box .closed-category-request {
  margin-top: 1.5rem;
  text-align: center;
}
.officeluv-category-container
  .child-categories-box
  .closed-category-request
  button {
  margin-top: 0.5rem;
  width: 100%;
  display: block;
}
.officeluv-category-container .child-categories-box .request-item-form {
  width: auto;
  margin-top: 1rem;
}
.officeluv-category-container .child-categories-box .request-item-form label {
  width: 100%;
  display: block;
  padding: 0;
}
.officeluv-category-container .child-categories-box .request-item-form input {
  border-radius: 0.25rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.officeluv-category-container .child-categories-box .request-item-form button {
  width: 100%;
  display: block;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@media (max-width: 480px) {
  .officeluv-category-container .flex-wrapper {
    display: block;
    padding-top: 0;
    width: 100%;
  }
  .officeluv-category-container .child-list-desktop {
    display: none;
  }

  .officeluv-category-container .child-list-mobile {
    display: block;
    padding-top: 2rem;
  }

  .officeluv-category-container .catalog-box.flex-item {
    width: calc(100% - 1rem);
    margin: 2rem 0 0 1rem;
  }

  .officeluv-category-container .variable-width-column {
    padding-left: 1rem;
  }
  .officeluv-category-container .child-categories-box.flex-item {
    width: 100%;
    margin: 0;
  }
  .variable-width-column.category-link-container {
    display: initial;
  }
}
