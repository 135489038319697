.cleaning-details-list {
  padding-top: 1rem;
  border-bottom: 2px solid #ebecee;
}
.cleaning-details-list-item {
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.cleaning-details-list-item.active {
  vertical-align: bottom;
}

.cleaning-details-list-item.active button,
.cleaning-details-list-item.active button:hover {
  color: white;
  background-color: #00a98c;
}
