.filters-summary {
  padding-top: 0.7rem;
}

.filter-summary {
  margin-left: 0.4rem;
}

.filter-summary:first-child {
  margin-left: 0;
}

.filter-summary-title {
  opacity: 0.75;
}

.filter-summary-label {
  font-weight: bold;
  color: #555c6e;
}

.filter-summary.warning,
.filter-summary.warning .filter-summary-label,
.filter-summary.warning .filter-summary-title {
  font-weight: bold;
  color: darkorange;
  opacity: 1;
}
