.cleaning-reports-list {
  display: inline-block;
  width: 11rem;
  vertical-align: top;
  border-right: 1px solid #535662;
  margin-right: 3rem;
  padding-bottom: 5rem;
}
.cleaning-reports-list-header {
  padding: 3.5rem 1.5rem 0.5rem 1rem;
  text-align: right;
}

.cleaning-reports-list-item a {
  text-align: right;
  width: 100%;
  padding-right: 1.5rem;
  border: 1px solid transparent;
  border-right: none;
  display: block;
}

.cleaning-reports-list-item a.primary {
  border: 1px solid #00a98c;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: none;
}
