.reset-password {
  margin: 1rem;
}
.reset-password label {
  display: block;
  margin: 1rem 0;
}
.reset-password label input {
  margin: 0.3rem 0 0.5rem;
  display: block;
}
.reset-password-message-error {
  color: white;
  background-color: red;
  padding: 0.5rem;
}
.reset-password-message-error-text {
  margin: 0.5rem;
}
.reset-password-sign-in {
  margin: 0.3rem 0 0.5rem;
}
