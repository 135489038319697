.modal-product-main-content.flex-wrapper {
  justify-content: space-between;
}
.modal-product-main-content .full-width.flex-wrapper {
  justify-content: space-between;
  align-items: baseline;
}

.modal-product .modal-product-main-content .product-price-details {
  width: 100%;
}

.modal-product .modal-product-main-content .product-details {
  width: 62%;
}

.modal-product .modal-product-main-content .product-image {
  width: 38%;
}
.modal-product .modal-product-main-content .product-description {
  white-space: pre-wrap;
}

.modal-product .modal-product-main-content .product-image img {
  width: 100%;
  height: auto;
  max-width: 233px;
}

.modal-product .modal-product-main-content .product-name {
  font-size: 1.25rem;
  line-height: 1.6rem;
}
.modal-product .modal-product-main-content .product-price {
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin: 0.3rem 0;
  font-weight: bold;
  display: inline-block;
}
.modal-product .modal-product-main-content .product-unit-data {
  line-height: 1.4rem;
  margin: 0.3rem 0 0;
}
.modal-product .modal-product-main-content .product-price-unit {
  display: inline-block;
  margin-left: 1rem;
}

.modal-product .modal-product-main-content .product-shipping {
  margin-top: 1rem;
  display: flex;
}
.modal-product
  .modal-product-main-content
  .product-details
  .product-shipping
  .shipping-time-icon {
  margin-right: 0.4rem;
  width: 2.3rem;
}
.modal-product
  .modal-product-main-content
  .product-details
  .product-shipping-detail {
  display: inline-block;
  vertical-align: middle;
}

.modal-product .product-actions-tabs {
  margin-top: 2rem;
  margin-bottom: -2px;
}

.product-actions-tabs .product-actions-tab {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: lightgrey;
  background: none;
  box-shadow: none;
  border: 2px solid white;
  border-bottom: none;
}
.product-actions-tabs .product-actions-tab:hover {
  cursor: pointer;
}
.product-actions-tabs .product-actions-tab {
  outline: 0;
}
.product-actions-tabs .product-actions-tab:focus {
  outline: 0;
}
.product-actions-tabs .product-actions-tab.active {
  color: #535662;
  border: 2px solid #f3f4f5;
  border-bottom: 2px solid white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-product .product-actions {
  border-top: 2px solid #f3f4f5;
  padding-top: 1rem;
}

button.modal-product.modal-close.close-icon {
  text-indent: -10000px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.modal-product .product-actions .modal-product-recurring-req-form {
  margin: 2rem 0 1rem;
}
.modal-product .modal-product-main-content .product-image:hover {
  cursor: zoom-in;
}
.modal-product-main-content.modal_product_image_zoom {
  flex-direction: column-reverse;
}
.modal-product-main-content.modal_product_image_zoom .product-details {
  width: 100%;
}
.modal-product-main-content.modal_product_image_zoom .product-image,
.modal-product-main-content.modal_product_image_zoom .product-image img {
  width: 100%;
  max-width: 100%;
}
.modal-product-main-content.modal_product_image_zoom .product-image:hover {
  cursor: zoom-out;
}

@media (max-width: 630px) {
  .modal.modal-product {
    padding: 3rem 1rem;
    margin-top: 0;
    margin-bottom: 0;
    max-height: 100vh;
  }
  .modal-product .product-actions-tabs {
    margin: 2rem -1rem -2px;
  }
  .modal-product-main-content.flex-wrapper {
    flex-direction: column-reverse;
  }
  .modal-product .modal-product-main-content .product-details {
    width: 100%;
  }

  .modal-product .modal-product-main-content .product-image {
    width: 100%;
  }
}
