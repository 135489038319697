.scheduled-requisitions {
}

.scheduled-requisitions-past {
  margin-bottom: 30px;
  display: inline-block;
  color: #bcbdc1;
  text-decoration: none;
  position: relative;
}

.scheduled-requisitions-past::before {
  position: absolute;
  content: '';
  width: 1rem;
  height: 1rem;
  background: #bcbdc1;
  border-radius: 50%;
  left: -2.05rem;
  top: 0;
  border: 1px solid #999daa;
}

.scheduled-requisitions-list-empty-state {
  height: 5rem;
}

.scheduled-requisitions .scheduled-requisitions-header {
  margin-bottom: 1rem;
}

.scheduled-requisitions .scheduled-requisitions-list {
  border-left: 1px solid #999daa;
  padding: 0 1rem 1rem 1.5rem;
}
.scheduled-requisitions .scheduled-requisitions-list.empty-state {
  padding-top: 3rem;
}
.scheduled-requisitions .scheduled-requisition-link {
  padding-left: 0;
}

.scheduled-requisitions .scheduled-requisition-approver-data-container {
  padding: 1rem 0 0.5rem 0;
}

.scheduled-requisitions .scheduled-requisition-subheader {
  padding: 1rem 0 0.5rem;
}
@media (max-width: 480px) {
  .scheduled-requisitions .scheduled-requisition-subheader {
    padding: 1rem 0 2rem;
  }
}

.scheduled-requisitions .scheduled-requisition-subheader a.tertiary {
  margin-left: -0.5rem;
}

.scheduled-requisitions .scheduled-requisition-date {
  padding-top: 2rem;
  position: relative;
}

.scheduled-requisitions .scheduled-requisition-date::before {
  position: absolute;
  content: '';
  width: 1rem;
  height: 1rem;
  background: white;
  border-radius: 50%;
  left: -2.1rem;
  top: 2rem;
  border: 1px solid #999daa;
}

.scheduled-requisitions .scheduled-requisition-remove {
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: grey;
  cursor: pointer;
}
