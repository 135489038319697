.calendar {
}

.calendar button,
.calendar button:hover {
  background: transparent;
  color: black;
  border: 1px solid #efefef;
  border-radius: 0;
  padding: 0.5rem 0;
}

/* react-calendar overrides */
.calendar button.react-calendar__month-view__days__day.selected {
  /*background-color: #fdfd6d;*/
  border: 1px solid #00a98c;
  box-shadow: inset 0 0 0 2px #00a98c;
}

.calendar .react-calendar__month-view__days {
  border: 1px solid #efefef;
}

.calendar button.react-calendar__navigation__label {
  border: none;
}
.calendar button.react-calendar__navigation__arrow {
  width: 50px;
  border: none;
  font-size: 2em;
}

.calendar button.react-calendar__navigation__prev2-button,
.calendar button.react-calendar__navigation__next2-button {
  display: none;
}

.calendar-view__container .back-to-today {
  width: 100%;
}
