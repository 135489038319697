.insights-budget-code-route .insights-budget-code {
  margin: 2rem 2rem 0 0;
  width: 60%;
  width: calc(100% - 22rem);
  display: inline-block;
  vertical-align: top;
}
.insights-budget-code-route .links-container {
  display: inline-block;
  width: 20rem;
  margin: 2rem 0;
}
.insights-budget-code-route .budget-code-links .budget-link {
  flex: 1;
  flex-basis: 100%;
  margin: 0 0 2rem 0;
}
.budget-insights-link.button {
  margin-left: -0.5rem;
}

@media (max-width: 768px) {
  .insights-budget-code-route .links-container {
    display: inline-block;
    width: 100%;
    margin: 0 0 4rem;
  }
  .insights-budget-code-route .insights-budget-code {
    margin: 2rem 0;
    width: 100%;
    display: block;
  }
  .insights-budget-code-route .budget-code-links {
    display: block;
  }
}
@media print {
  .insights-budget-code-route .insights-budget-code {
    margin: 2rem 0;
    width: 100%;
    display: block;
  }
  .insights-budget-code-route .budget-code-links {
    display: none;
  }
}
