.requisition-item-details {
  margin-bottom: 5rem;
  padding: 0.5rem;
  border: 1px solid #f3f4f5;
  border-radius: 0.25rem;
  background-color: white;
}

.requisition-item-details h4.details-header {
  padding: 0 0 0.5rem 0.5rem;
}

.requisition-item-products .shipment-product-container:nth-child(even) {
  background-color: #f3f4f5;
}

.requisition-item-products .shipment-product-container:last-child {
  margin-bottom: 1rem;
}

.requisition-item-details
  .budget-code-group
  .budget-code-header
  h4.budget-code-name,
.requisition-item-details
  .budget-code-group
  .budget-code-header
  button.link-to-budget-code {
  display: inline-block;
  width: 50%;
}

.requisition-item-details .budget-code-group button.link-to-budget-code {
  margin: 1rem 0;
}

.no-border {
  border: 0 !important;
}

@media print {
  .requisition-item-details {
    border: none;
    margin: 0 auto;
    width: 100%;
  }
}
