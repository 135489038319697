.cleaning-report {
  display: inline-block;
  width: 100%;
}

.cleaning-report .cleaning-header-container {
  padding-left: 1rem;
}

.cleaning-report .cleaning-header-container h2 {
  padding-bottom: 1rem;
}

.cleaning-report .cleaning-details-container.flex-wrapper {
  width: 100%;
  justify-content: space-around;
}

.cleaning-report .shift-notes.flex-item {
  flex-basis: 20rem;
  min-width: 150px;
  align-self: flex-start;
}

.cleaning-report .cleaning-comments-list-container .shift-notes {
  border-top: 1px solid #535662;
  padding: 1rem;
}

.cleaning-report .cleaning-comments-list-container h3 {
  vertical-align: bottom;
}

.cleaning-report-reviews {
  margin-bottom: 3rem;
  margin-left: -3rem;
  padding-left: 3.25rem;
}

.cleaning-report .one-off-task-item,
.cleaning-report .recurring-task-item {
  /* border: none; */
  box-shadow: none;
}

.cleaning-report .recurring-task-items:hover .recurring-task-item:hover,
.cleaning-report .one-off-task-items:hover .one-off-task-item:hover {
  box-shadow: none;
  cursor: default;
}

@media (max-width: 1100px) {
  .shift-notes.flex-item {
    flex-basis: 100%;
    margin-right: 0;
  }
}

@media (max-width: 1050px) {
  .cleaning-report .shift-notes.flex-item,
  .cleaning-report .cleaning-details-tasks-container.flex-item {
    flex-basis: 100%;
    margin-right: 0;
  }
}
