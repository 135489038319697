.insights-downloads {
  padding: 0 0 1px 2rem;
  max-width: 22rem;
}

.insights-downloads-title {
  display: inline-block;
  margin-bottom: 1rem;
  vertical-align: text-top;
}
.insights-downloads-links {
  display: inline-block;
}
.insights-downloads-links a {
  display: inline-block;
  background-color: #00a98c;
  color: white;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-decoration: none;
}
.insights-downloads-links a:hover {
  background-color: #00a98c;
  transition: background-color 0.15s ease-in;
}
.insights-downloads-links a:first-child {
  margin-right: 1rem;
}
