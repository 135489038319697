.external-vendor-invoice.ui.segment {
  margin-bottom: 20vh;
}
.external-vendor-invoice .list-header {
  padding-bottom: 0.4em;
}

.external-vendor-invoice .list-item {
  font-size: 1em;
}
.external-vendor-invoice .ui.table td a {
  font-size: 1em;
  text-decoration: none;
}
