.supplies-order-route {
  padding-bottom: 5rem;
}
.supplies-order-container.flex-wrapper {
  max-width: 55rem;
}

.shipments-orders-container {
  flex-grow: 1;
  max-width: 50rem;
  margin-bottom: 4rem;
}

.shipments-orders-container .shipments-wrap {
  margin-bottom: 2rem;
}

.shipments-orders-container h3.section-header {
  margin-bottom: 1rem;
}
.shipments-orders-container .edit-budget-codes-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: 0.7rem;
}

.delivery-info-container {
  vertical-align: top;
  margin: 0.25rem 1rem 0 2rem;
  flex: 1;
}

.requisition-right-rail {
  margin-top: -8px;
}

.supplies-order-container a.order-edit-link {
  display: inline-block;
  margin-bottom: 1.5rem;
  background-color: #00a98c;
  color: white;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-decoration: none;
}

@media print {
  .supplies-order-route {
    padding-bottom: 0;
  }

  .supplies-order-container.fixed-width-column.flex-wrapper {
    width: 100%;
  }

  .shipments-orders-container {
    margin-bottom: 0;
    max-width: 100%;
  }

  .requisition-items {
    margin: 0 auto;
    min-width: 100%;
  }
}

@media (max-width: 450px) {
  .supplies-order-container a.order-edit-link {
    display: inline-block;
    margin-bottom: 1.5rem;
  }
}
