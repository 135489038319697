.shipment-item.purchase-order-item {
  width: 100%;
  max-width: 100%;
  background-color: white;
}

.shipment-item.purchase-order-item.received {
  width: 100%;
  max-width: 100%;
  background-color: white;
}

.shipment-item h4.purchase-order-item-status {
  margin-bottom: 0.5rem;
}
