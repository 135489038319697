.calendar-month-day {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 15px;
  position: relative;
}

i.icon.calendar-month-day__icon {
  margin-right: 5px;
  margin-top: 5px;
  font-size: 0.75rem;
  height: 10px;
  width: 10px;
}

.calendar-month-day__dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 2px solid grey;
  margin-right: 5px;
  margin-top: 5px;
}

.calendar-month-day__dot:last-child {
  margin-right: 0;
}

.calendar a.calendar-month-day__add {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 1rem;
  color: darkgrey;
  text-decoration: none;
  display: none;
}

/*** react calendar overrides ***/

.calendar
  button.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  cursor: not-allowed;
  background: #efefef;
}

.calendar button.react-calendar__month-view__days__day {
  outline: 0;
}

.calendar
  button.react-calendar__month-view__days__day:hover
  a.calendar-month-day__add {
  display: block !important;
}

.calendar .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-weight: bold;
}
