form.cleaning-task-form {
  padding: 2rem 0 1rem 0;
}

.cleaning-task-form-update-header {
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.cleaning-task-form input[type='text'],
textarea.cleaning-task-form-input.note-area,
.cleaning-task-form-radio-container {
  width: calc(62%);
}

textarea.cleaning-task-form-input.note-area {
  height: 4rem;
  resize: none;
}

.cleaning-task-form-radio-container {
  margin-bottom: 2rem;
}

.cleaning-task-form-radio-container label {
  display: block;
  padding: 0.5rem;
  border-radius: 4px;
  max-width: 22rem;
  cursor: pointer;
}

.cleaning-task-form-radio-container label:first-child {
  display: none;
}
.cleaning-task-form-radio-container label:nth-child(2),
.cleaning-task-form-radio-container.recurring label:nth-child(3) {
  color: #00a98c;
  background-color: #fafdfc;
  border: 1px solid #00a98c;
}
.cleaning-task-form-radio-container.recurring label:nth-child(2),
.cleaning-task-form-radio-container label:nth-child(3) {
  border: 1px solid transparent;
  background-color: white;
  color: #555c6e;
}

.cleaning-task-form-radio-container input[type='radio'] {
  display: inline-block;
}

.cleaning-task-form-radio-container .input-radio-label {
  display: inline;
  margin-left: 0.5rem;
}

.cleaning-task-form-radio-container .input-radio-sublabel {
  margin-left: 1.75rem;
}

.cleaning-task-form.submit-button {
  margin: 1rem 0 2rem 0;
  display: block;
}

button.cleaning-task-delete-button.tertiary {
  display: block;
  color: #f18770;
  background-color: transparent;
  background-image: url('./../../imgs/icon-delete--salmon.svg');
  background-position-y: 0.3rem;
}

button.cleaning-task-delete-button.tertiary:hover {
  background-color: #f18770;
  color: white;
  background-image: url('./../../imgs/icon-trash--white.svg');
}
