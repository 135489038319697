.product-sort-item {
  padding: 0 1rem;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
}

.product-sort-item:hover,
.product-sort-item.active {
  color: #00a98c;
}

.product-sort-item .direction {
  position: absolute;
  top: -2px;
  right: 0;
  font-size: 18px;
  margin-left: 5px;
}

.product-sort-item .direction::before {
  content: '▾';
}

.product-sort-item .direction.asc {
  transform: rotate(180deg);
}

.product-sort-item .direction.hidden {
  visibility: hidden;
}

@media (max-width: 450px) {
  .product-sort > strong {
    display: block;
  }

  .product-sort-item {
    padding: 0 0.8rem;
    font-size: 12px;
  }
}
