.attachment-preview-wrapper.flex-wrapper {
  display: inline-flex;
  width: 18rem;
}

.attachment-preview.image {
  margin-right: 0.5rem;
}

.attachment-preview.image img {
  height: auto;
}

.attachment-preview.meta-data {
  flex-direction: column;
}
.attachment-preview.meta-data .ui.button {
  margin-left: 0;
}

.attachment-preview-remove {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  text-align: left;
  box-shadow: none;
}

.collapsed-preview-attachment.flex-wrapper {
  margin: 0.5rem 0.5rem 0.5rem 0;
  border-radius: 0.1rem;
  box-shadow: 0 0 4px 0 #b4b4b4;
  max-width: 7rem;
}

.collapsed-preview.name {
  padding: 0 0.25rem 0 0.25rem;
}

.collapsed-preview.icon {
  width: 1.5rem;
  background-color: #c6c4c1;
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
  margin-right: 0.25rem;
}
