.task-items-list-header {
  padding-left: 1rem;
}

.task-items-list-header img,
.task-items-list-header h3 {
  display: inline-block;
  vertical-align: bottom;
}

.task-items-list-header img {
  margin-right: 0.5rem;
  height: 2.127rem;
}

.task-items-list button.primary {
  width: 9rem;
  height: 9rem;
  background-color: white;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3rem;
  border-radius: 0;
  border: solid 1px #00a98c;
  color: #00a98c;
  margin-right: 1rem;
}
.task-items-list button.primary .plus-icon {
  font-weight: 100;
  font-size: 3rem;
  line-height: 3.5rem;
  display: block;
}

.task-items-list .task-header {
  margin-bottom: 0.25rem;
}

.task-items-list .task-header p {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.task-items-list .empty-state {
  margin-top: 3.2rem;
  text-align: left;
  width: calc(100% - 10rem);
  color: #bcbdc1;
}

.completed-empty-state {
  color: #8f949f;
  margin: 1rem 0 1rem 0.5rem;
}

.task-items-list .recurring-task-items .empty-state {
  background-repeat: no-repeat;
  background-position: center;
}

.to-do-container {
  margin: 0 0 1rem 0;
  padding: 1.5rem 0.5rem 1.5rem 1.5rem;
  background-color: white;
  border-top: 1px solid #535662;
}

.recurring-task-items:hover,
.one-off-task-items:hover {
  transition: background-color 0.08s ease-in;
}

.one-off-header {
  font-size: 1rem;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.recurring-header {
  font-size: 1rem;
}

@media (max-width: 450px) {
  .to-do-container {
    padding: 1rem 0 1rem 1rem;
  }
}
