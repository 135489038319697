.draggable {
  position: relative;
  width: 100%;
}

button.draggable-control-wrap:hover {
  cursor: grab;
}

button.draggable-control-wrap:active {
  cursor: grabbing;
}

button.draggable-control-wrap:active i.icon.draggable-control,
button.draggable-control-wrap:hover i.icon.draggable-control {
  color: #00a98c;
  transition: all 0.25s ease-in;
  cursor: move;
}

i.icon.draggable-control {
  color: #bbb;
  margin: 0;
  width: 9px;
}

i.icon.move-to-top {
  color: #ddd;
}

i.icon.move-to-top:hover {
  color: #00a98c;
  transition: all 0.25s ease-in;
}

.draggable-phantom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}
