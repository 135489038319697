.budget-code-products {
  margin: 2rem 0 5rem 0;
}
.budget-code-products .header {
  margin-bottom: 1rem;
}
.budget-code-products .header .row-one {
  display: block;
}
.budget-code-products .product-row.flex-wrapper {
  margin-bottom: 0;
  align-items: center;
  border-top: 1px dashed #bcbdc1;
}
.budget-code-products .header-row .flex-wrapper {
  border-top: none;
  padding: 0.5rem 0;
}
.budget-code-products .product-details,
.budget-code-products .product-details-header {
  display: inline-block;
  padding: 0.5rem;
}
.budget-code-products .product-details-header {
  background: none;
  box-shadow: none;
  color: inherit;
  padding: 0 0.5rem;
}
.budget-code-products .product-details-header.count,
.budget-code-products .product-details.count,
.budget-code-products .product-details-header.price,
.budget-code-products .product-details.price,
.budget-code-products .product-details-header.submitted-date,
.budget-code-products .product-details.submitted-date,
.budget-code-products .product-details-header.vendor_name {
  text-align: right;
}

.budget-code-products .product-details-header.name,
.budget-code-products .product-details.name {
  width: 30%;
  text-align: left;
}
.budget-code-products .product-details-header.vendor_name,
.budget-code-products .product-details.vendor_name {
  width: 17%;
  text-align: right;
}
.budget-code-products .product-details-header.count,
.budget-code-products .product-details.count {
  width: 16%;
}
.budget-code-products .product-details-header.price,
.budget-code-products .product-details.price {
  width: 17%;
}
.budget-code-products .product-details-header.submitted-date,
.budget-code-products .product-details.submitted-date {
  width: 20%;
}
.budget-code-products img.sort-arrow {
  display: inline-block;
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: -0.25rem;
  vertical-align: text-bottom;
}
.budget-code-products img.sort-arrow.up {
  transform: rotate(180deg);
}
