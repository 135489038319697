.insights-budgets-container {
  width: 100%;
  margin: 2rem auto 0;
  padding-bottom: 15rem;
}
.insights-budgets-container .flex-wrapper {
  justify-content: space-between;
  margin-bottom: 2rem;
}
.insights-budget-code-spend,
.insights-vendor-spend {
  display: inline-block;
  width: 60%;
  width: calc(100% - 22rem);
  vertical-align: top;
}
.insights-budget-code-spend {
  margin-bottom: 2rem;
}
.spend-to-budget-progress-pie {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 2rem;
}
.insights-budget-progress-container {
  display: inline-block;
  width: 22rem;
  vertical-align: top;
}
.insights-budget-progress-container
  .spend-to-budget-progress-pie
  .progress-pie {
  max-width: 220px;
  margin: 0 auto;
}

.insights-budget-spend .total-office-spend-line,
.insights-budget-spend .hide-when-printing.office-budget-settings-container {
  display: inline-block;
  vertical-align: top;
}
.insights-budget-spend .total-office-spend-line {
  width: 60%;
  width: calc(100% - 22rem);
  background-color: white;
  border-top: 1px solid #535662;
}
@media print {
  .insights-budgets-container .insight-data.print-pdf {
    display: block;
  }
  .insights-budget-progress-container,
  .insights-vendor-spend,
  .insights-budget-code-spend {
    min-height: 200px;
    display: block;
    width: 100%;
    page-break-before: always;
  }
  .insights-vendor-spend .table-wrapper,
  .insights-budget-code-spend .table-wrapper {
    display: block;
    width: 100%;
  }
  .insights-vendor-spend .pie-wrapper,
  .insights-budget-code-spend .pie-wrapper {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
}

@media (max-width: 450px) {
  .insights-budgets-container .insights-budget-spend {
    display: block;
  }

  .insights-budget-spend .total-office-spend-line,
  .insights-budgets-container .insights-vendor-spend,
  .insights-budgets-container .insights-budget-code-spend,
  .insights-budgets-container .spend-to-budget-progress-pie {
    display: block;
    width: 100%;
  }
  .insights-budgets-container .spend-to-budget-progress-pie {
    margin-bottom: 2rem;
    padding: 0;
  }

  .insights-budgets-container
    .insights-budget-spend
    .office-budget-settings-container {
    display: block;
  }
}

@media (max-width: 330px) {
}

@media (max-width: 650px) {
}
