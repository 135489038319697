.change-password {
}
.change-password label {
  display: block;
  margin: 1rem 0;
}
.change-password label input {
  display: block;
}
.change-password-message-error {
  color: white;
  background-color: red;
  padding: 0.5rem;
}
.change-password-message-error-text {
  margin: 0.5rem;
}
.change-password-sign-in {
  margin: 0.3rem 0 0.5rem;
}
