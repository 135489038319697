.unified-order .header {
  margin-bottom: 2rem;
}

.unified-order .col-right {
  flex: 1;
  margin-left: 2rem;
}

.unified-order .width-contain {
  max-width: 500px;
}

.unified-order .SingleDatePickerInput__withBorder {
  border: none;
}
