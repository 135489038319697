.modal-confirm {
  margin-top: 5rem;
}

.modal-confirm__content {
  position: relative;
  padding: 2rem;
}

.modal-confirm__actions {
  display: flex;
  justify-content: space-evenly;
}

.modal-confirm__message {
  font-size: 1rem;
  text-align: center;
  padding: 3rem 1rem;
}

.modal-confirm__message p {
  font-size: 1rem;
}

button.modal-confirm.modal-close.close-icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
