textarea.resize--vertical {
  resize: vertical;
}

textarea.resize--horizontal {
  resize: horizontal;
}

textarea.resize--none {
  resize: none;
}
