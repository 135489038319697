.insights-controls .flex-item .location-select,
.insights-controls .flex-item .date-picker {
  display: inline-block;
  vertical-align: top;
  margin-right: 1em;
}

.insights-controls .flex-item .location-select {
  width: 15rem;
}
.insights-controls .flex-item .location-select > div > div > div:hover {
  cursor: pointer;
}

.insights-controls .DateInput_fang {
  top: 38px !important;
}
.insights-controls .DateRangePicker_picker__directionLeft {
  top: 49px !important;
}
.insights-controls .ui.selection.dropdown {
  height: 2.56em;
  min-height: 2em;
  min-width: 8rem;
  color: #535662;
}
.DateRangePickerInput__withBorder {
  border-radius: 0;
  border: 1px solid #bcbdc1;
}
.DateInput {
  background: none;
}
.DateInput.DateInput_1 input {
  background-color: white;
  padding: 6px 12px 4px;
  margin: inherit;
  border: inherit;
  line-height: inherit;
  font-weight: inherit;
  font-size: 1rem;
  border: 1px solid white;
}
.DateInput.DateInput_1 input:hover {
  cursor: pointer;
}
.DateInput.DateInput_1 input:focus {
  border: 1px solid #02b4c5;
  box-shadow: 0 0.05rem 0.25rem #02b4c5;
}
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #00a699;
  border: 1px solid #00a699;
}

.report-download-container {
  flex: 1;
  padding-left: 2rem;
  max-width: 22rem;
  text-align: left;
}
.insights-budget-code .insights-controls .report-download-container {
  display: none;
}
.DayPickerNavigation_button {
  line-height: 0.78 !important;
}

@media (max-width: 872px) {
  .report-download-container {
    display: none;
  }
}

@media (max-width: 450px) {
  .insights-controls .selector-container .location-select,
  .insights-controls .selector-container .date-picker,
  .insights-controls .flex-item.report-download-container {
    margin-top: 0.5rem;
  }
}
