.ui.menu.budget-code-links .item.active {
  padding-left: 0.5rem;
  position: relative;
}
.ui.menu.budget-code-links .item.active::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #00a98c;
}
