.modal-product-recurring-req-form .recurring-req-select {
  width: 40%;
}
.modal-product-recurring-req-form .recurring-req-select label {
  padding: 0;
  width: 100%;
}
.modal-product-recurring-req-form .recurring-req-select select {
  margin: 0;
  width: 100%;
  padding: 0.6rem 1.75rem 0.6rem 0.75rem;
}
.modal-product-recurring-req-form .input-row {
  align-items: flex-end;
  justify-content: space-between;
}

.modal-product-recurring-req-form .input-quantity-label {
  margin-left: 1.1rem;
}
.modal-product-recurring-req-form input[type='number'].input-quantity {
  margin-bottom: 0;
}

@media (max-width: 630px) {
  .modal-product-recurring-req-form .recurring-req-select {
    width: 60%;
  }
  .modal-product-recurring-req-form .recurring-req-quantity {
    text-align: center;
  }
  .modal-product-recurring-req-form .input-quantity-label {
    margin-left: 0;
  }
  .modal-product-recurring-req-form .recurring-req-submit {
    width: 100%;
    margin-top: 1rem;
  }
  .modal-product-recurring-req-form .input-row .submit {
    width: 100%;
  }
}
