.product-filters {
  margin-top: -0.5em;
  flex-wrap: wrap;
}
.product-filter {
  width: 180px;
}

.ui.stackable.product-filters.menu {
  padding-left: 2rem;
}

.ui.menu.product-filters > .item {
  padding: 0.92857143em 1em;
  padding-bottom: 1em;
  padding-left: 0;
}
