.modal-hotkeys-title {
  font-size: initial;
  padding: 1rem 2rem;
  color: initial;
}
.modal .hotkeys-table {
  padding: 1rem 2rem;
}

.modal table.hotkeys-table {
  display: block;
  width: 100%;
  overflow: auto;
  border-spacing: 0;
  border-collapse: collapse;
}

.modal table.hotkeys-table th {
  font-weight: 700;
}

.modal table.hotkeys-table th,
.modal table.hotkeys-table td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.modal table.hotkeys-table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}

.modal table.hotkeys-table tr:nth-child(2n) {
  background-color: #f6f8fa;
}
