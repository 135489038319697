.avatar {
  display: inline-block;
}

.avatar svg,
.avatar img {
  border-radius: 3rem;
}
.avatar svg text {
  text-transform: uppercase;
}
.avatar img[alt='Someone Anonymous'],
.avatar svg[alt='Someone Anonymous'] {
  border-radius: 0;
}

.employee-avatar .avatar svg,
.employee-avatar .avatar img {
  border: 4px solid #a45faa;
  box-sizing: content-box;
}

.staff-avatar .avatar svg,
.staff-avatar .avatar img {
  border: 4px solid #00a98c;
  box-sizing: content-box;
}
