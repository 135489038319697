.invoicing-preferences-invoice {
  margin-bottom: 1rem;
  box-shadow: 10px 0 50px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  background: white;
}
.invoicing-preferences .ui.selection.dropdown .menu > .item,
.invoicing-preferences .ui.checkbox label {
  font-size: 0.875rem;
}
