.budget-code-products .product-row .product-details a {
  display: block;
  vertical-align: middle;
}
.budget-code-products .product-row .product-details .unlinked {
  color: #535662;
  cursor: initial;
}

.budget-code-products .product-row .product-details .unlinked:hover {
  background-color: transparent;
}
.budget-code-products .product-row .product-details .product-item-image {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  height: 25px;
}
.budget-code-products .product-row .product-details span {
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 30px - 0.5rem);
}

.budget-code-products .product-row .name-text.formatted {
  text-transform: capitalize;
}
