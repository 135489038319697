.officeluv-category-catalog {
  padding-bottom: 50vh;
}

.category-catalog-child {
  padding: 0.5rem 0 1rem;
}

.category-row.flex-wrapper {
  flex-wrap: wrap;
}

.flex-wrapper.category-subset {
  flex-wrap: wrap;
  margin-left: -1rem;
}

.category-row .product-item {
  display: inline-block;
  flex: none;
  width: 177px;
  height: 350px;
  margin: 0.5rem 0 1rem 0.5rem;
  background-color: white;
  border: 2px solid #f2f2f2;
}

.category-catalog-child h4,
.category-catalog-your h4,
.category-catalog-popular h4,
.category-row h4 {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.3rem;
}

.officeluv-category-catalog .tertiary-link {
  line-height: 1rem !important;
  font-size: 0.875rem !important;
}

.category-catalog-your.category-row {
  padding-top: 0;
}

@media (max-width: 480px) {
  .category-row .product-item {
    display: block;
    flex: none;
    width: 100%;
    height: auto;
    margin: 0.5rem 0;
    background-color: white;
    border: 2px solid #f2f2f2;
  }
}
