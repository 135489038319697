.cleanings-empty h1.ui.header {
  font-size: 2.4rem;
  line-height: 3.5rem;
}

.cleanings-empty .ui.items > .item > .content > .header {
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #535662;
  margin-bottom: 0.8rem;
}

.cleanings-empty .ui.items > .item {
  margin: 2.5rem 0;
}

.cleanings-empty .ui.items > .item > .content > p {
  font-size: 1rem;
  line-height: 1.5;
}

.cleanings-empty h4.ui.header {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 1.35rem;
  color: #666a77;
  text-transform: uppercase;
}
