.customer-billing-info {
  margin-bottom: 4rem;
}
.customer-billing-info .stripe-billing-info,
.customer-billing-info .quickbooks-billing-info {
  margin-bottom: 3rem;
}

.update-bill-info-success {
  background-color: #dfe8b8;
  color: #92b307;
  font-weight: bold;
  padding: 1rem;
  margin-top: 1rem;
}

.update-bill-info-success .success-checkmark {
  margin-bottom: 1rem;
}

.update-bill-info-success .success-received {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: middle;
}

.update-bill-info-success .checkmark {
  width: 3rem;
  height: 3rem;
  vertical-align: middle;
  margin-left: 0.5rem;
}
.billing-info-item h4 {
  position: relative;
}
.billing-info-item h4 .active {
  position: absolute;
  top: 0px;
  background: #00a98c;
  color: white;
  padding: 0rem 0.25rem;
  border-radius: 0.25rem;
  font-size: smaller;
  margin-left: 0.25rem;
  line-height: 1.25rem;
}
