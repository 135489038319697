.ui.segment.requisition-scheduled-info {
  margin: 0 0 2rem;
  padding: 0;
}

.ui.basic.loading.segment.requisition-scheduled-info {
  width: 100px;
  height: 100px;
}

.ui.loading.segment.requisition-scheduled-info:before {
  background: none transparent;
}

.requisition-scheduled-info .schedule-history a {
  text-decoration: none;
}
