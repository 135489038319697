.product-item-image img {
  border-radius: 0.25rem;
}

.product-item-list-row .product-item-image img {
  width: 144px;
}

.product-item .product-item-image img.empty-state {
  width: 9rem;
  /*height: 5.568rem;*/
  padding: 1.618rem;
  margin: 0 auto 0.5rem;
  display: block;
}

.in-cart .product-item-image img {
  opacity: 0.25;
}
