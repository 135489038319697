.contact-us {
    margin: 5rem 0;
    padding-left: 5rem;
    max-width:50rem;

    @media (max-width: 450px) {
      margin: 1rem 0;
      padding-left: 0rem;
    }
  }

  .contact-us sub {
    margin-top: 1rem;
  }

  .contact-us h2 {
    margin-top: 20px;
  }

  .contact-us form {
    margin-bottom: 20px;
  }

  .contact-us .contact-us-notification {
    text-align: center;
    font-size: 1rem;
  }

  .contact-us .contact-us-notification i {
    margin-top: 15px;
  }

  .contact-us .contact-us-notification .check {
    color: #00a98c;
  }

  .contact-us .contact-us-notification .warning {
    color: #912d2b;
  }